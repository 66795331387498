<template>
  <BaseLoader fullscreen />
</template>

<script setup lang="ts">
import type { GetAccessTokenResponse } from '@/composables/helpers/AuthHelper';

const { getAccessToken } = useAuthHelper();
const router = useRouter();

onMounted(async () => {
  logger.info('Getting new token from window location');

  const loginToken: GetAccessTokenResponse = await getAccessToken(window.location.toString());

  if (loginToken && !(loginToken instanceof NobitaError)) {
    router.replace(loginToken.state || '/');
  } else {
    routeToLogin(router);
  }
});
</script>
