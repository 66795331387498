<template>
  <h1 id="GlobalHeaderTitle" class="Title">
    <BaseIcon class="SiteTitle">
      <NobitaIconHenngeOne class="HenngeLogo" />
    </BaseIcon>
    <BaseIcon class="ProductIcon">
      <NobitaIconLogo />
    </BaseIcon>
    {{ $t('globalHeader.title') }}
    <BetaTag />
  </h1>
</template>

<style scoped lang="scss">
// See global.scss for .GlobalAction, .GlobalActionHolder styles

.Title {
  display: flex;
  align-items: center;
  color: neutral(100);
  font-weight: 400;
  font-size: 16px;
  margin: 0;
  padding: 13px 16px;
  text-decoration: none;
}

.HenngeLogo {
  fill: white();
}

.SiteTitle {
  width: 108px;
  height: 13px;
}

.ProductIcon {
  margin: 0 2px 0 16px;
}
</style>
