/* tslint:disable */
/* eslint-disable */
/**
 * HENNGE Email Archive API
 * HENNGE Email Archive administration and search API.
 *
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { CustomAccessPolicyV0 } from './CustomAccessPolicyV0';
import {
    type instanceOfCustomAccessPolicyV0,
    CustomAccessPolicyV0FromJSON,
    CustomAccessPolicyV0FromJSONTyped,
    CustomAccessPolicyV0ToJSON,
} from './CustomAccessPolicyV0';
import type { NamedAccessPolicyV0 } from './NamedAccessPolicyV0';
import {
    type instanceOfNamedAccessPolicyV0,
    NamedAccessPolicyV0FromJSON,
    NamedAccessPolicyV0FromJSONTyped,
    NamedAccessPolicyV0ToJSON,
} from './NamedAccessPolicyV0';

/**
 * @type AccessPolicyV0
 * 
 * @export
 */
export type AccessPolicyV0 = { type: 'custom' } & CustomAccessPolicyV0 | { type: 'named' } & NamedAccessPolicyV0;

export function AccessPolicyV0FromJSON(json: any): AccessPolicyV0 {
    return AccessPolicyV0FromJSONTyped(json, false);
}

export function AccessPolicyV0FromJSONTyped(json: any, ignoreDiscriminator: boolean): AccessPolicyV0 {
    if (json == null) {
        return json;
    }
    switch (json['type']) {
        case 'custom':
            return Object.assign({}, CustomAccessPolicyV0FromJSONTyped(json, true), { type: 'custom' } as const);
        case 'named':
            return Object.assign({}, NamedAccessPolicyV0FromJSONTyped(json, true), { type: 'named' } as const);
        default:
            throw new Error(`No variant of AccessPolicyV0 exists with 'type=${json['type']}'`);
    }
}

export function AccessPolicyV0ToJSON(value?: AccessPolicyV0 | null): any {
    if (value == null) {
        return value;
    }
    switch (value['type']) {
        case 'custom':
            return CustomAccessPolicyV0ToJSON(value);
        case 'named':
            return NamedAccessPolicyV0ToJSON(value);
        default:
            throw new Error(`No variant of AccessPolicyV0 exists with 'type=${value['type']}'`);
    }

}

