/* tslint:disable */
/* eslint-disable */
/**
 * HENNGE Email Archive API
 * HENNGE Email Archive administration and search API.
 *
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { CustomSearchPolicyInListV0 } from './CustomSearchPolicyInListV0';
import {
    instanceOfCustomSearchPolicyInListV0,
    CustomSearchPolicyInListV0FromJSON,
    CustomSearchPolicyInListV0FromJSONTyped,
    CustomSearchPolicyInListV0ToJSON,
} from './CustomSearchPolicyInListV0';
import type { SystemSearchPolicyInListV0 } from './SystemSearchPolicyInListV0';
import {
    instanceOfSystemSearchPolicyInListV0,
    SystemSearchPolicyInListV0FromJSON,
    SystemSearchPolicyInListV0FromJSONTyped,
    SystemSearchPolicyInListV0ToJSON,
} from './SystemSearchPolicyInListV0';

/**
 * @type SearchPolicyInListV0
 * 
 * @export
 */
export type SearchPolicyInListV0 = { type: 'custom' } & CustomSearchPolicyInListV0 | { type: 'system' } & SystemSearchPolicyInListV0;

export function SearchPolicyInListV0FromJSON(json: any): SearchPolicyInListV0 {
    return SearchPolicyInListV0FromJSONTyped(json, false);
}

export function SearchPolicyInListV0FromJSONTyped(json: any, ignoreDiscriminator: boolean): SearchPolicyInListV0 {
    if (json == null) {
        return json;
    }
    switch (json['type']) {
        case 'custom':
            return Object.assign({}, CustomSearchPolicyInListV0FromJSONTyped(json, true), { type: 'custom' } as const);
        case 'system':
            return Object.assign({}, SystemSearchPolicyInListV0FromJSONTyped(json, true), { type: 'system' } as const);
        default:
            throw new Error(`No variant of SearchPolicyInListV0 exists with 'type=${json['type']}'`);
    }
}

export function SearchPolicyInListV0ToJSON(value?: SearchPolicyInListV0 | null): any {
    if (value == null) {
        return value;
    }
    switch (value['type']) {
        case 'custom':
            return CustomSearchPolicyInListV0ToJSON(value);
        case 'system':
            return SystemSearchPolicyInListV0ToJSON(value);
        default:
            throw new Error(`No variant of SearchPolicyInListV0 exists with 'type=${value['type']}'`);
    }

}

