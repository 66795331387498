<template>
  <DomainTemplate>
    <DomainField
      v-show="!loader.isLoading"
      :model-value="stored_domainName ?? ''"
      @submit="submitHandler"
    />
  </DomainTemplate>

  <FailedOnboardingDialog />
</template>

<script setup lang="ts">
import type { ProviderRequestV0 } from '@/api';

const { getLoginUrl } = useAuthHelper();
const { snackbars, loader, dialog } = useStores('snackbars', 'loader', 'dialog');
const route = useRoute();
const {
  stored_domainName,
  stored_autoReLogin,
  stored_loginHint,
  stored_loginErrorCode,
  stored_loginErrorContext,
  stored_currentRoute,
} = useNobitaLocalStorage();
const { loginDomainInputAction } = useAnalyticsHelper();

const providerReq = ref<ProviderRequestV0>({ domainName: '' });
const isDomainSet = computed<boolean>(() => providerReq.value.domainName !== '');

async function login() {
  if (isDomainSet.value) {
    loginDomainInputAction(!!route.query.domain);

    try {
      loader.show();

      providerReq.value.state = stored_currentRoute.value ?? '';
      const url = await getLoginUrl(providerReq.value);

      window.location.replace(url);
    } catch (e) {
      loader.hide();
      logger.error('Failed to get IdP url', e);

      snackbars.addWarningSnackbar();

      providerReq.value.domainName = '';
      providerReq.value.state = undefined;
    }
  }
}

function submitHandler(domain: string) {
  providerReq.value.domainName = domain;
  stored_domainName.value = domain;
  login();
}

function setDomain() {
  if (route.query.domain && typeof route.query.domain === 'string') {
    logger.debug(`Set login domain from query parameter to: ${route.query.domain}`);
    providerReq.value.domainName = route.query.domain;

    if (route.query.domain === stored_domainName.value) {
      logger.debug(`Login hint is set to: ${stored_loginHint.value}`);
      providerReq.value.loginHint = stored_loginHint.value;
    }
  } else {
    logger.debug(`Login hint is set to: ${stored_loginHint.value}`);
    providerReq.value.loginHint = stored_loginHint.value;

    if (stored_autoReLogin.value && stored_domainName.value) {
      logger.debug(`We will auto-login with this domain name: ${stored_domainName.value}`);
      providerReq.value.domainName = stored_domainName.value;
    }

    // Always set this to false after the above check to reset the state
    stored_autoReLogin.value = false;
  }
}

onMounted(() => {
  setDomain();

  if (!hadLoginError() && isDomainSet.value) {
    login();
  }
});

// Login error
function hadLoginError() {
  if (stored_loginErrorCode.value !== '') {
    if (stored_loginErrorContext.value === 'onboarding_request') {
      dialog.attemptOpen('FailedOnboardingDialog');
    } else {
      snackbars.addWarningSnackbar('response.errors.loginFailed');
    }
    stored_loginErrorCode.value = '';
    stored_loginErrorContext.value = '';
    return true;
  }
  return false;
}
</script>
