/* tslint:disable */
/* eslint-disable */
/**
 * HENNGE Email Archive API
 * HENNGE Email Archive administration and search API.
 *
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { SearchConditionV0 } from './SearchConditionV0';
import {
    SearchConditionV0FromJSON,
    SearchConditionV0FromJSONTyped,
    SearchConditionV0ToJSON,
} from './SearchConditionV0';

/**
 * 
 * @export
 * @interface SearchEntryV0
 */
export interface SearchEntryV0 {
    /**
     * 
     * @type {Array<SearchConditionV0>}
     * @memberof SearchEntryV0
     */
    conditions?: Array<SearchConditionV0>;
    /**
     * 
     * @type {Date}
     * @memberof SearchEntryV0
     */
    end: Date;
    /**
     * Unique identifier for Search Entry
     * @type {string}
     * @memberof SearchEntryV0
     */
    id: string;
    /**
     * 
     * @type {Date}
     * @memberof SearchEntryV0
     */
    start: Date;
    /**
     * 
     * @type {boolean}
     * @memberof SearchEntryV0
     */
    success: boolean;
    /**
     * 
     * @type {Date}
     * @memberof SearchEntryV0
     */
    timestamp: Date;
}

/**
 * Check if a given object implements the SearchEntryV0 interface.
 */
export function instanceOfSearchEntryV0(value: object): value is SearchEntryV0 {
    if (!('end' in value) || value['end'] === undefined) return false;
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('start' in value) || value['start'] === undefined) return false;
    if (!('success' in value) || value['success'] === undefined) return false;
    if (!('timestamp' in value) || value['timestamp'] === undefined) return false;
    return true;
}

export function SearchEntryV0FromJSON(json: any): SearchEntryV0 {
    return SearchEntryV0FromJSONTyped(json, false);
}

export function SearchEntryV0FromJSONTyped(json: any, ignoreDiscriminator: boolean): SearchEntryV0 {
    if (json == null) {
        return json;
    }
    return {
        
        'conditions': json['conditions'] == null ? undefined : ((json['conditions'] as Array<any>).map(SearchConditionV0FromJSON)),
        'end': (new Date(json['end'])),
        'id': json['id'],
        'start': (new Date(json['start'])),
        'success': json['success'],
        'timestamp': (new Date(json['timestamp'])),
    };
}

export function SearchEntryV0ToJSON(value?: SearchEntryV0 | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'conditions': value['conditions'] == null ? undefined : ((value['conditions'] as Array<any>).map(SearchConditionV0ToJSON)),
        'end': ((value['end']).toISOString()),
        'id': value['id'],
        'start': ((value['start']).toISOString()),
        'success': value['success'],
        'timestamp': ((value['timestamp']).toISOString()),
    };
}

