/* tslint:disable */
/* eslint-disable */
/**
 * HENNGE Email Archive API
 * HENNGE Email Archive administration and search API.
 *
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { SearchPolicyRulesV0 } from './SearchPolicyRulesV0';
import {
    SearchPolicyRulesV0FromJSON,
    SearchPolicyRulesV0FromJSONTyped,
    SearchPolicyRulesV0ToJSON,
} from './SearchPolicyRulesV0';

/**
 * Used when creating Search Policy.
 * @export
 * @interface SearchPolicyCreateV0
 */
export interface SearchPolicyCreateV0 {
    /**
     * 
     * @type {string}
     * @memberof SearchPolicyCreateV0
     */
    name: string;
    /**
     * 
     * @type {Array<SearchPolicyRulesV0>}
     * @memberof SearchPolicyCreateV0
     */
    rules: Array<SearchPolicyRulesV0>;
}

/**
 * Check if a given object implements the SearchPolicyCreateV0 interface.
 */
export function instanceOfSearchPolicyCreateV0(value: object): value is SearchPolicyCreateV0 {
    if (!('name' in value) || value['name'] === undefined) return false;
    if (!('rules' in value) || value['rules'] === undefined) return false;
    return true;
}

export function SearchPolicyCreateV0FromJSON(json: any): SearchPolicyCreateV0 {
    return SearchPolicyCreateV0FromJSONTyped(json, false);
}

export function SearchPolicyCreateV0FromJSONTyped(json: any, ignoreDiscriminator: boolean): SearchPolicyCreateV0 {
    if (json == null) {
        return json;
    }
    return {
        
        'name': json['name'],
        'rules': ((json['rules'] as Array<any>).map(SearchPolicyRulesV0FromJSON)),
    };
}

export function SearchPolicyCreateV0ToJSON(value?: SearchPolicyCreateV0 | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'name': value['name'],
        'rules': ((value['rules'] as Array<any>).map(SearchPolicyRulesV0ToJSON)),
    };
}

