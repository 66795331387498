/* tslint:disable */
/* eslint-disable */
/**
 * HENNGE Email Archive API
 * HENNGE Email Archive administration and search API.
 *
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { PreservationPeriodWithBackupPeriodGte10YearsV0 } from './PreservationPeriodWithBackupPeriodGte10YearsV0';
import {
    instanceOfPreservationPeriodWithBackupPeriodGte10YearsV0,
    PreservationPeriodWithBackupPeriodGte10YearsV0FromJSON,
    PreservationPeriodWithBackupPeriodGte10YearsV0FromJSONTyped,
    PreservationPeriodWithBackupPeriodGte10YearsV0ToJSON,
} from './PreservationPeriodWithBackupPeriodGte10YearsV0';
import type { PreservationPeriodWithBackupPeriodLt10YearsV0 } from './PreservationPeriodWithBackupPeriodLt10YearsV0';
import {
    instanceOfPreservationPeriodWithBackupPeriodLt10YearsV0,
    PreservationPeriodWithBackupPeriodLt10YearsV0FromJSON,
    PreservationPeriodWithBackupPeriodLt10YearsV0FromJSONTyped,
    PreservationPeriodWithBackupPeriodLt10YearsV0ToJSON,
} from './PreservationPeriodWithBackupPeriodLt10YearsV0';

/**
 * @type PreservationPeriodV0
 * 
 * @export
 */
export type PreservationPeriodV0 = { frozenPeriod: 'gte_10years' } & PreservationPeriodWithBackupPeriodGte10YearsV0 | { frozenPeriod: 'lt_10years' } & PreservationPeriodWithBackupPeriodLt10YearsV0;

export function PreservationPeriodV0FromJSON(json: any): PreservationPeriodV0 {
    return PreservationPeriodV0FromJSONTyped(json, false);
}

export function PreservationPeriodV0FromJSONTyped(json: any, ignoreDiscriminator: boolean): PreservationPeriodV0 {
    if (json == null) {
        return json;
    }
    switch (json['frozen_period']) {
        case 'gte_10years':
            return Object.assign({}, PreservationPeriodWithBackupPeriodGte10YearsV0FromJSONTyped(json, true), { frozenPeriod: 'gte_10years' } as const);
        case 'lt_10years':
            return Object.assign({}, PreservationPeriodWithBackupPeriodLt10YearsV0FromJSONTyped(json, true), { frozenPeriod: 'lt_10years' } as const);
        default:
            throw new Error(`No variant of PreservationPeriodV0 exists with 'frozenPeriod=${json['frozenPeriod']}'`);
    }
}

export function PreservationPeriodV0ToJSON(value?: PreservationPeriodV0 | null): any {
    if (value == null) {
        return value;
    }
    switch (value['frozenPeriod']) {
        case 'gte_10years':
            return PreservationPeriodWithBackupPeriodGte10YearsV0ToJSON(value);
        case 'lt_10years':
            return PreservationPeriodWithBackupPeriodLt10YearsV0ToJSON(value);
        default:
            throw new Error(`No variant of PreservationPeriodV0 exists with 'frozenPeriod=${value['frozenPeriod']}'`);
    }

}

