import type { SearchConditionV0 } from '@/api';
import type { TranslationKey } from '@/locales';
import type { DateRange } from '@/types/DateRange';
import type { SearchRangeOption } from '@/types/SearchRange';
import type {
  SearchConditionFieldEnum,
  SearchConditionTextTypeEnum,
  SearchConditionWithId,
  SearchReferrerOptions,
  SearchStoreType,
} from '@/types/SearchSettings';

import { cloneDeep } from 'lodash-es';

// Keep this here as a kind of static global for unique IDs
// Start it at 100 so it doesn't collide with loaded IDs from query parameters
// The number doesn't matter, only needs to guarantee uniqueness
let conditionId = 100;

export const getNewConditionId = () => {
  return conditionId++;
};

const getInitialState = (): SearchStoreType => {
  const { tenant } = useStores('tenant');

  return {
    dates: SearchRanges[DEFAULT_SEARCH_RANGE_OPTION].getDateRange(tenant.timezone),
    rangeIndex: DEFAULT_SEARCH_RANGE_OPTION,
    referrer: '',
    anyCondition: '',
    conditions: [],
    status_error: false,
    dialog: {
      dates: SearchRanges[DEFAULT_SEARCH_RANGE_OPTION].getDateRange(tenant.timezone),
      rangeIndex: DEFAULT_SEARCH_RANGE_OPTION,
      anyCondition: '',
    },
    temp: {
      dates: SearchRanges[DEFAULT_SEARCH_RANGE_OPTION].getDateRange(tenant.timezone),
      rangeIndex: DEFAULT_SEARCH_RANGE_OPTION,
      anyCondition: '',
    },
  };
};

export const useSearchStore = defineStore('search', () => {
  const tenant = useTenantStore();

  const state = reactive<SearchStoreType>(getInitialState());

  return {
    ...toRefs(state),

    // validation
    setErrorStatus(param: boolean) {
      state.status_error = param;
    },

    setAnyCondition(param: string) {
      state.anyCondition = param;
    },

    // remove for disable sync between toolbar and dialog
    setTempConditions() {
      state.temp.anyCondition = state.anyCondition;
      state.temp.dates = cloneDeep(state.dates);
      state.temp.rangeIndex = state.rangeIndex;
    },

    setDialogConditions() {
      state.dialog.anyCondition = state.anyCondition;
      state.dialog.dates = cloneDeep(state.dates);
      state.dialog.rangeIndex = state.rangeIndex;
    },

    errorMessage: computed<TranslationKey | ''>(() => {
      const { validateDateRange } = useValidator();
      return validateDateRange(state.dates.start, state.dates.end);
    }),

    hasError: computed<boolean>(() => {
      const { validateDateRange } = useValidator();
      return (
        validateDateRange(state.dates.start, state.dates.end) !== '' ||
        (state.status_error ? state.status_error : false)
      );
    }),

    // dates
    getDateRangeById(id: SearchRangeOption, timezone: string = tenant.timezone): DateRange {
      if (id === 'custom') return state.dates;
      return SearchRanges[id].getDateRange(timezone);
    },

    async setDateRangeById(id: SearchRangeOption, timezone: string = tenant.timezone) {
      state.dates = SearchRanges[id].getDateRange(timezone);
    },

    setDates(range: DateRange) {
      state.dates = cloneDeep(range);
    },

    setRangeIndex(index: SearchRangeOption): void {
      state.rangeIndex = index;
    },

    setReferrer(referrer: SearchReferrerOptions): void {
      state.referrer = referrer;
    },

    // conditions
    addToValuesList(index: number, value: string): void {
      const condition = state.conditions[index];
      if (isTextTypeSearchCondition(condition))
        condition.values = [...new Set([...condition.values, value])];
    },

    deleteFromValuesList(index: number, valueIndex: number): void {
      const condition = state.conditions[index];
      if (isTextTypeSearchCondition(condition)) condition.values.splice(valueIndex, 1);
    },

    setCondition(index: number, condition: SearchConditionV0): void {
      state.conditions[index] = { ...cloneDeep(condition), id: state.conditions[index].id };
    },

    setConditionField(index: number, params: SearchConditionFieldEnum): void {
      state.conditions[index].field = params;
    },

    setConditionType(index: number, params: SearchConditionTextTypeEnum): void {
      const condition = state.conditions[index];
      if (isTextTypeSearchCondition(condition)) {
        condition.type = params;
      }
    },

    clearAllConditions(): void {
      state.conditions = [];
    },

    setConditions(conditions: SearchConditionWithId[], isDiscard?: boolean): void {
      state.conditions = cloneDeep(conditions);

      // Set `anyCondition` if it exists.
      const anyIndex = state.conditions.findIndex((condition) => condition.field === 'any');
      if (anyIndex > -1 && isAnyTypeSearchCondition(conditions[anyIndex])) {
        if (!isDiscard) state.anyCondition = conditions[anyIndex].values[0];
        state.conditions.splice(anyIndex, 1);
      }

      // If there are no non-trivial conditions left, add a starter one.
      // This only applies to the `search` store, not the `searchSettings` store.
      // The `searchSettings` store is allowed to have an empty array.
      if (!state.conditions.length) {
        this.addCondition();
      }
    },

    setConditionsWithoutIds(conditions: SearchConditionV0[]): void {
      this.setConditions(cloneDeep(conditions).map((c) => ({ ...c, id: getNewConditionId() })));
    },

    cleanConditions(): void {
      const temp: SearchConditionWithId[] = [];

      for (const condition of state.conditions) {
        if (condition.field === 'has_attachment' || condition.values.length) {
          temp.push(condition);
        }
      }

      this.setConditions(temp);
    },

    hasAttachmentField(): boolean {
      return state.conditions.filter((e) => e.field === 'has_attachment').length > 0;
    },

    hasStorageStatusField(): boolean {
      return state.conditions.filter((e) => e.field === 'storage_status').length > 0;
    },

    addCondition(): void {
      state.conditions.push({
        field: 'envelope_from_envelope_to',
        type: 'contain',
        values: [],
        id: getNewConditionId(),
      });
    },

    deleteCondition(index: number): void {
      state.conditions.splice(index, 1);
    },

    discardChanges(): void {
      const { searchSettings } = useStores('searchSettings');

      // This timeout is required to let the dialog close animation finish before we revert the changes.
      setTimeout(() => {
        // revert the dirty changes here back to the temporary search's conditions
        if (state.rangeIndex !== state.temp.rangeIndex) {
          this.setRangeIndex(state.temp.rangeIndex);
        }
        if (!isEquivalentUnsorted(state.dates, state.temp.dates)) {
          this.setDates(state.temp.dates);
        }
        if (!isEquivalentUnsorted(state.anyCondition, state.temp.anyCondition)) {
          this.setAnyCondition(state.temp.anyCondition);
        }

        this.setConditions(searchSettings.conditions, true);
      }, TRANSITION_MS);
    },
  };
});

export default useSearchStore;

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useSearchStore, import.meta.hot));
}
