/* tslint:disable */
/* eslint-disable */
/**
 * HENNGE Email Archive API
 * HENNGE Email Archive administration and search API.
 *
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { PreservationPeriodV0 } from './PreservationPeriodV0';
import {
    PreservationPeriodV0FromJSON,
    PreservationPeriodV0FromJSONTyped,
    PreservationPeriodV0ToJSON,
} from './PreservationPeriodV0';

/**
 * 
 * @export
 * @interface ArchiveSettingsV0
 */
export interface ArchiveSettingsV0 {
    /**
     * 
     * @type {boolean}
     * @memberof ArchiveSettingsV0
     */
    isArchiving: boolean;
    /**
     * 
     * @type {PreservationPeriodV0}
     * @memberof ArchiveSettingsV0
     */
    preservationPeriod: PreservationPeriodV0;
}

/**
 * Check if a given object implements the ArchiveSettingsV0 interface.
 */
export function instanceOfArchiveSettingsV0(value: object): value is ArchiveSettingsV0 {
    if (!('isArchiving' in value) || value['isArchiving'] === undefined) return false;
    if (!('preservationPeriod' in value) || value['preservationPeriod'] === undefined) return false;
    return true;
}

export function ArchiveSettingsV0FromJSON(json: any): ArchiveSettingsV0 {
    return ArchiveSettingsV0FromJSONTyped(json, false);
}

export function ArchiveSettingsV0FromJSONTyped(json: any, ignoreDiscriminator: boolean): ArchiveSettingsV0 {
    if (json == null) {
        return json;
    }
    return {
        
        'isArchiving': json['is_archiving'],
        'preservationPeriod': PreservationPeriodV0FromJSON(json['preservation_period']),
    };
}

export function ArchiveSettingsV0ToJSON(value?: ArchiveSettingsV0 | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'is_archiving': value['isArchiving'],
        'preservation_period': PreservationPeriodV0ToJSON(value['preservationPeriod']),
    };
}

