/* tslint:disable */
/* eslint-disable */
/**
 * HENNGE Email Archive API
 * HENNGE Email Archive administration and search API.
 *
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * Used when updating Domain.
 * @export
 * @interface DomainUpdateV0
 */
export interface DomainUpdateV0 {
    /**
     * Timezone to set for the domain. This must be a valid entry from the tz database (also known as the IANA time zone database), for example Asia/Tokyo or Europe/London.
     * @type {string}
     * @memberof DomainUpdateV0
     */
    timezone?: string | null;
}

/**
 * Check if a given object implements the DomainUpdateV0 interface.
 */
export function instanceOfDomainUpdateV0(value: object): value is DomainUpdateV0 {
    return true;
}

export function DomainUpdateV0FromJSON(json: any): DomainUpdateV0 {
    return DomainUpdateV0FromJSONTyped(json, false);
}

export function DomainUpdateV0FromJSONTyped(json: any, ignoreDiscriminator: boolean): DomainUpdateV0 {
    if (json == null) {
        return json;
    }
    return {
        
        'timezone': json['timezone'] == null ? undefined : json['timezone'],
    };
}

export function DomainUpdateV0ToJSON(value?: DomainUpdateV0 | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'timezone': value['timezone'],
    };
}

