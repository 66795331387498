/* tslint:disable */
/* eslint-disable */
/**
 * HENNGE Email Archive API
 * HENNGE Email Archive administration and search API.
 *
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ArchivedPeriodSearchPolicyRuleV0
 */
export interface ArchivedPeriodSearchPolicyRuleV0 {
    /**
     * 
     * @type {string}
     * @memberof ArchivedPeriodSearchPolicyRuleV0
     */
    end: string;
    /**
     * 
     * @type {string}
     * @memberof ArchivedPeriodSearchPolicyRuleV0
     */
    field: ArchivedPeriodSearchPolicyRuleV0FieldEnum;
    /**
     * 
     * @type {string}
     * @memberof ArchivedPeriodSearchPolicyRuleV0
     */
    start: string;
}


/**
 * @export
 */
export const ArchivedPeriodSearchPolicyRuleV0FieldEnum = {
    Period: 'period'
} as const;
export type ArchivedPeriodSearchPolicyRuleV0FieldEnum = typeof ArchivedPeriodSearchPolicyRuleV0FieldEnum[keyof typeof ArchivedPeriodSearchPolicyRuleV0FieldEnum];


/**
 * Check if a given object implements the ArchivedPeriodSearchPolicyRuleV0 interface.
 */
export function instanceOfArchivedPeriodSearchPolicyRuleV0(value: object): value is ArchivedPeriodSearchPolicyRuleV0 {
    if (!('end' in value) || value['end'] === undefined) return false;
    if (!('field' in value) || value['field'] === undefined) return false;
    if (!('start' in value) || value['start'] === undefined) return false;
    return true;
}

export function ArchivedPeriodSearchPolicyRuleV0FromJSON(json: any): ArchivedPeriodSearchPolicyRuleV0 {
    return ArchivedPeriodSearchPolicyRuleV0FromJSONTyped(json, false);
}

export function ArchivedPeriodSearchPolicyRuleV0FromJSONTyped(json: any, ignoreDiscriminator: boolean): ArchivedPeriodSearchPolicyRuleV0 {
    if (json == null) {
        return json;
    }
    return {
        
        'end': json['end'],
        'field': json['field'],
        'start': json['start'],
    };
}

export function ArchivedPeriodSearchPolicyRuleV0ToJSON(value?: ArchivedPeriodSearchPolicyRuleV0 | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'end': value['end'],
        'field': value['field'],
        'start': value['start'],
    };
}

