/* tslint:disable */
/* eslint-disable */
/**
 * HENNGE Email Archive API
 * HENNGE Email Archive administration and search API.
 *
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { SearchConditionV0 } from './SearchConditionV0';
import {
    SearchConditionV0FromJSON,
    SearchConditionV0FromJSONTyped,
    SearchConditionV0ToJSON,
} from './SearchConditionV0';

/**
 * 
 * @export
 * @interface SearchQueryV0
 */
export interface SearchQueryV0 {
    /**
     * skip results after and including this token
     * @type {string}
     * @memberof SearchQueryV0
     */
    backtoken?: string | null;
    /**
     * 
     * @type {Array<SearchConditionV0>}
     * @memberof SearchQueryV0
     */
    conditions?: Array<SearchConditionV0>;
    /**
     * 
     * @type {Date}
     * @memberof SearchQueryV0
     */
    end: Date;
    /**
     * maximum number of results to return
     * @type {number}
     * @memberof SearchQueryV0
     */
    limit?: number;
    /**
     * skip results up to and including this token
     * @type {string}
     * @memberof SearchQueryV0
     */
    skiptoken?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof SearchQueryV0
     */
    start: Date;
}

/**
 * Check if a given object implements the SearchQueryV0 interface.
 */
export function instanceOfSearchQueryV0(value: object): value is SearchQueryV0 {
    if (!('end' in value) || value['end'] === undefined) return false;
    if (!('start' in value) || value['start'] === undefined) return false;
    return true;
}

export function SearchQueryV0FromJSON(json: any): SearchQueryV0 {
    return SearchQueryV0FromJSONTyped(json, false);
}

export function SearchQueryV0FromJSONTyped(json: any, ignoreDiscriminator: boolean): SearchQueryV0 {
    if (json == null) {
        return json;
    }
    return {
        
        'backtoken': json['backtoken'] == null ? undefined : json['backtoken'],
        'conditions': json['conditions'] == null ? undefined : ((json['conditions'] as Array<any>).map(SearchConditionV0FromJSON)),
        'end': (new Date(json['end'])),
        'limit': json['limit'] == null ? undefined : json['limit'],
        'skiptoken': json['skiptoken'] == null ? undefined : json['skiptoken'],
        'start': (new Date(json['start'])),
    };
}

export function SearchQueryV0ToJSON(value?: SearchQueryV0 | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'backtoken': value['backtoken'],
        'conditions': value['conditions'] == null ? undefined : ((value['conditions'] as Array<any>).map(SearchConditionV0ToJSON)),
        'end': ((value['end']).toISOString()),
        'limit': value['limit'],
        'skiptoken': value['skiptoken'],
        'start': ((value['start']).toISOString()),
    };
}

