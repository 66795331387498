/* tslint:disable */
/* eslint-disable */
/**
 * HENNGE Email Archive API
 * HENNGE Email Archive administration and search API.
 *
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const ArchiveStatusV0 = {
    Available: 'available',
    Defrosted: 'defrosted',
    Defrosting: 'defrosting',
    Frozen: 'frozen'
} as const;
export type ArchiveStatusV0 = typeof ArchiveStatusV0[keyof typeof ArchiveStatusV0];


export function instanceOfArchiveStatusV0(value: any): boolean {
    for (const key in ArchiveStatusV0) {
        if (Object.prototype.hasOwnProperty.call(ArchiveStatusV0, key)) {
            if (ArchiveStatusV0[key as keyof typeof ArchiveStatusV0] === value) {
                return true;
            }
        }
    }
    return false;
}

export function ArchiveStatusV0FromJSON(json: any): ArchiveStatusV0 {
    return ArchiveStatusV0FromJSONTyped(json, false);
}

export function ArchiveStatusV0FromJSONTyped(json: any, ignoreDiscriminator: boolean): ArchiveStatusV0 {
    return json as ArchiveStatusV0;
}

export function ArchiveStatusV0ToJSON(value?: ArchiveStatusV0 | null): any {
    return value as any;
}

