/* tslint:disable */
/* eslint-disable */
/**
 * HENNGE Email Archive API
 * HENNGE Email Archive administration and search API.
 *
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AccessPolicyArchivePeriodOptionV0
 */
export interface AccessPolicyArchivePeriodOptionV0 {
    /**
     * 
     * @type {string}
     * @memberof AccessPolicyArchivePeriodOptionV0
     */
    id: AccessPolicyArchivePeriodOptionV0IdEnum;
    /**
     * 
     * @type {string}
     * @memberof AccessPolicyArchivePeriodOptionV0
     */
    value: AccessPolicyArchivePeriodOptionV0ValueEnum;
}


/**
 * @export
 */
export const AccessPolicyArchivePeriodOptionV0IdEnum = {
    ArchivePeriod: 'archive_period'
} as const;
export type AccessPolicyArchivePeriodOptionV0IdEnum = typeof AccessPolicyArchivePeriodOptionV0IdEnum[keyof typeof AccessPolicyArchivePeriodOptionV0IdEnum];

/**
 * @export
 */
export const AccessPolicyArchivePeriodOptionV0ValueEnum = {
    Read: 'read'
} as const;
export type AccessPolicyArchivePeriodOptionV0ValueEnum = typeof AccessPolicyArchivePeriodOptionV0ValueEnum[keyof typeof AccessPolicyArchivePeriodOptionV0ValueEnum];


/**
 * Check if a given object implements the AccessPolicyArchivePeriodOptionV0 interface.
 */
export function instanceOfAccessPolicyArchivePeriodOptionV0(value: object): value is AccessPolicyArchivePeriodOptionV0 {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('value' in value) || value['value'] === undefined) return false;
    return true;
}

export function AccessPolicyArchivePeriodOptionV0FromJSON(json: any): AccessPolicyArchivePeriodOptionV0 {
    return AccessPolicyArchivePeriodOptionV0FromJSONTyped(json, false);
}

export function AccessPolicyArchivePeriodOptionV0FromJSONTyped(json: any, ignoreDiscriminator: boolean): AccessPolicyArchivePeriodOptionV0 {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'value': json['value'],
    };
}

export function AccessPolicyArchivePeriodOptionV0ToJSON(value?: AccessPolicyArchivePeriodOptionV0 | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'value': value['value'],
    };
}

