/* tslint:disable */
/* eslint-disable */
/**
 * HENNGE Email Archive API
 * HENNGE Email Archive administration and search API.
 *
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface KeywordSearchPolicyRuleV0
 */
export interface KeywordSearchPolicyRuleV0 {
    /**
     * 
     * @type {string}
     * @memberof KeywordSearchPolicyRuleV0
     */
    field: KeywordSearchPolicyRuleV0FieldEnum;
    /**
     * 
     * @type {Array<string>}
     * @memberof KeywordSearchPolicyRuleV0
     */
    values?: Array<string>;
}


/**
 * @export
 */
export const KeywordSearchPolicyRuleV0FieldEnum = {
    Keyword: 'keyword'
} as const;
export type KeywordSearchPolicyRuleV0FieldEnum = typeof KeywordSearchPolicyRuleV0FieldEnum[keyof typeof KeywordSearchPolicyRuleV0FieldEnum];


/**
 * Check if a given object implements the KeywordSearchPolicyRuleV0 interface.
 */
export function instanceOfKeywordSearchPolicyRuleV0(value: object): value is KeywordSearchPolicyRuleV0 {
    if (!('field' in value) || value['field'] === undefined) return false;
    return true;
}

export function KeywordSearchPolicyRuleV0FromJSON(json: any): KeywordSearchPolicyRuleV0 {
    return KeywordSearchPolicyRuleV0FromJSONTyped(json, false);
}

export function KeywordSearchPolicyRuleV0FromJSONTyped(json: any, ignoreDiscriminator: boolean): KeywordSearchPolicyRuleV0 {
    if (json == null) {
        return json;
    }
    return {
        
        'field': json['field'],
        'values': json['values'] == null ? undefined : json['values'],
    };
}

export function KeywordSearchPolicyRuleV0ToJSON(value?: KeywordSearchPolicyRuleV0 | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'field': value['field'],
        'values': value['values'],
    };
}

