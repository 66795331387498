/* tslint:disable */
/* eslint-disable */
/**
 * HENNGE Email Archive API
 * HENNGE Email Archive administration and search API.
 *
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ArchiveHeadersV0 } from './ArchiveHeadersV0';
import {
    ArchiveHeadersV0FromJSON,
    ArchiveHeadersV0FromJSONTyped,
    ArchiveHeadersV0ToJSON,
} from './ArchiveHeadersV0';

/**
 * 
 * @export
 * @interface ArchiveSearchResultsV0
 */
export interface ArchiveSearchResultsV0 {
    /**
     * 
     * @type {string}
     * @memberof ArchiveSearchResultsV0
     */
    backtoken: string | null;
    /**
     * 
     * @type {number}
     * @memberof ArchiveSearchResultsV0
     */
    limit?: number | null;
    /**
     * 
     * @type {Array<ArchiveHeadersV0>}
     * @memberof ArchiveSearchResultsV0
     */
    results: Array<ArchiveHeadersV0>;
    /**
     * 
     * @type {string}
     * @memberof ArchiveSearchResultsV0
     */
    skiptoken: string | null;
    /**
     * 
     * @type {number}
     * @memberof ArchiveSearchResultsV0
     */
    total: number;
}

/**
 * Check if a given object implements the ArchiveSearchResultsV0 interface.
 */
export function instanceOfArchiveSearchResultsV0(value: object): value is ArchiveSearchResultsV0 {
    if (!('backtoken' in value) || value['backtoken'] === undefined) return false;
    if (!('results' in value) || value['results'] === undefined) return false;
    if (!('skiptoken' in value) || value['skiptoken'] === undefined) return false;
    if (!('total' in value) || value['total'] === undefined) return false;
    return true;
}

export function ArchiveSearchResultsV0FromJSON(json: any): ArchiveSearchResultsV0 {
    return ArchiveSearchResultsV0FromJSONTyped(json, false);
}

export function ArchiveSearchResultsV0FromJSONTyped(json: any, ignoreDiscriminator: boolean): ArchiveSearchResultsV0 {
    if (json == null) {
        return json;
    }
    return {
        
        'backtoken': json['backtoken'],
        'limit': json['limit'] == null ? undefined : json['limit'],
        'results': ((json['results'] as Array<any>).map(ArchiveHeadersV0FromJSON)),
        'skiptoken': json['skiptoken'],
        'total': json['total'],
    };
}

export function ArchiveSearchResultsV0ToJSON(value?: ArchiveSearchResultsV0 | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'backtoken': value['backtoken'],
        'limit': value['limit'],
        'results': ((value['results'] as Array<any>).map(ArchiveHeadersV0ToJSON)),
        'skiptoken': value['skiptoken'],
        'total': value['total'],
    };
}

