<template>
  <BaseDropdown :active align="bottom" justify="right">
    <template #items>
      <DropdownItem
        class="LanguageDropdown"
        is-submenu
        @mouseover="showDropdown"
        @mouseleave="hideDropdown"
      >
        {{ $t('globalHeader.language') }}

        <LanguageMenu
          class="LanguageDropdownMenu"
          submenu
          :active="isLangActive"
          @close="closeHandler"
        />
      </DropdownItem>
      <DropdownItem @click="logoutHandler">{{ $t('globalHeader.logOut') }}</DropdownItem>
    </template>
  </BaseDropdown>
</template>

<script setup lang="ts">
defineProps<{
  active?: boolean;
}>();

const emit = defineEmits<{
  close: [];
}>();

const { logout } = useAuthHelper();
const { tenant } = useStores('tenant');
const { stored_loginHint, stored_accessToken, stored_currentRoute } = useNobitaLocalStorage();

// languages
const isLangActive = ref(false);

function showDropdown() {
  isLangActive.value = true;
}
function hideDropdown() {
  isLangActive.value = false;
}

function closeHandler() {
  isLangActive.value = false;
  emit('close');
}

const router = useRouter();

// logout
async function logoutHandler() {
  // get a copy of the access token first
  const accessTokenStr = stored_accessToken.value;

  // clear LocalStorage variables
  stored_loginHint.value = null;
  stored_accessToken.value = null;
  stored_currentRoute.value = null;

  tenant.setLoggedInStatus(false);

  // invoke logout procedure via API
  if (accessTokenStr) {
    await logout(accessTokenStr);
  }

  routeToLogin(router);
}
</script>

<style scoped lang="scss">
.LanguageDropdown {
  cursor: default;
}

.LanguageDropdownMenu {
  margin-top: -8px;
}
</style>
