/* tslint:disable */
/* eslint-disable */
/**
 * HENNGE Email Archive API
 * HENNGE Email Archive administration and search API.
 *
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface EnvelopeFromSearchConditionV0
 */
export interface EnvelopeFromSearchConditionV0 {
    /**
     * 
     * @type {string}
     * @memberof EnvelopeFromSearchConditionV0
     */
    field: EnvelopeFromSearchConditionV0FieldEnum;
    /**
     * 
     * @type {string}
     * @memberof EnvelopeFromSearchConditionV0
     */
    type: EnvelopeFromSearchConditionV0TypeEnum;
    /**
     * 
     * @type {Array<string>}
     * @memberof EnvelopeFromSearchConditionV0
     */
    values: Array<string>;
}


/**
 * @export
 */
export const EnvelopeFromSearchConditionV0FieldEnum = {
    EnvelopeFrom: 'envelope_from'
} as const;
export type EnvelopeFromSearchConditionV0FieldEnum = typeof EnvelopeFromSearchConditionV0FieldEnum[keyof typeof EnvelopeFromSearchConditionV0FieldEnum];

/**
 * @export
 */
export const EnvelopeFromSearchConditionV0TypeEnum = {
    Contain: 'contain',
    Match: 'match',
    NotContain: 'not_contain',
    NotMatch: 'not_match'
} as const;
export type EnvelopeFromSearchConditionV0TypeEnum = typeof EnvelopeFromSearchConditionV0TypeEnum[keyof typeof EnvelopeFromSearchConditionV0TypeEnum];


/**
 * Check if a given object implements the EnvelopeFromSearchConditionV0 interface.
 */
export function instanceOfEnvelopeFromSearchConditionV0(value: object): value is EnvelopeFromSearchConditionV0 {
    if (!('field' in value) || value['field'] === undefined) return false;
    if (!('type' in value) || value['type'] === undefined) return false;
    if (!('values' in value) || value['values'] === undefined) return false;
    return true;
}

export function EnvelopeFromSearchConditionV0FromJSON(json: any): EnvelopeFromSearchConditionV0 {
    return EnvelopeFromSearchConditionV0FromJSONTyped(json, false);
}

export function EnvelopeFromSearchConditionV0FromJSONTyped(json: any, ignoreDiscriminator: boolean): EnvelopeFromSearchConditionV0 {
    if (json == null) {
        return json;
    }
    return {
        
        'field': json['field'],
        'type': json['type'],
        'values': json['values'],
    };
}

export function EnvelopeFromSearchConditionV0ToJSON(value?: EnvelopeFromSearchConditionV0 | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'field': value['field'],
        'type': value['type'],
        'values': value['values'],
    };
}

