/* tslint:disable */
/* eslint-disable */
/**
 * HENNGE Email Archive API
 * HENNGE Email Archive administration and search API.
 *
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ArchiveStatusV0 } from './ArchiveStatusV0';
import {
    ArchiveStatusV0FromJSON,
    ArchiveStatusV0FromJSONTyped,
    ArchiveStatusV0ToJSON,
} from './ArchiveStatusV0';

/**
 * 
 * @export
 * @interface ArchiveStatusDetailV0
 */
export interface ArchiveStatusDetailV0 {
    /**
     * 
     * @type {number}
     * @memberof ArchiveStatusDetailV0
     */
    defrostDurationMs: number | null;
    /**
     * 
     * @type {Date}
     * @memberof ArchiveStatusDetailV0
     */
    defrostedFrom: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof ArchiveStatusDetailV0
     */
    defrostedUntil: Date | null;
    /**
     * 
     * @type {ArchiveStatusV0}
     * @memberof ArchiveStatusDetailV0
     */
    status: ArchiveStatusV0;
}



/**
 * Check if a given object implements the ArchiveStatusDetailV0 interface.
 */
export function instanceOfArchiveStatusDetailV0(value: object): value is ArchiveStatusDetailV0 {
    if (!('defrostDurationMs' in value) || value['defrostDurationMs'] === undefined) return false;
    if (!('defrostedFrom' in value) || value['defrostedFrom'] === undefined) return false;
    if (!('defrostedUntil' in value) || value['defrostedUntil'] === undefined) return false;
    if (!('status' in value) || value['status'] === undefined) return false;
    return true;
}

export function ArchiveStatusDetailV0FromJSON(json: any): ArchiveStatusDetailV0 {
    return ArchiveStatusDetailV0FromJSONTyped(json, false);
}

export function ArchiveStatusDetailV0FromJSONTyped(json: any, ignoreDiscriminator: boolean): ArchiveStatusDetailV0 {
    if (json == null) {
        return json;
    }
    return {
        
        'defrostDurationMs': json['defrost_duration_ms'],
        'defrostedFrom': (json['defrosted_from'] == null ? null : new Date(json['defrosted_from'])),
        'defrostedUntil': (json['defrosted_until'] == null ? null : new Date(json['defrosted_until'])),
        'status': ArchiveStatusV0FromJSON(json['status']),
    };
}

export function ArchiveStatusDetailV0ToJSON(value?: ArchiveStatusDetailV0 | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'defrost_duration_ms': value['defrostDurationMs'],
        'defrosted_from': (value['defrostedFrom'] == null ? null : (value['defrostedFrom'] as any).toISOString()),
        'defrosted_until': (value['defrostedUntil'] == null ? null : (value['defrostedUntil'] as any).toISOString()),
        'status': ArchiveStatusV0ToJSON(value['status']),
    };
}

