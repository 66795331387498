/* tslint:disable */
/* eslint-disable */
/**
 * HENNGE Email Archive API
 * HENNGE Email Archive administration and search API.
 *
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { SearchConditionV0 } from './SearchConditionV0';
import {
    SearchConditionV0FromJSON,
    SearchConditionV0FromJSONTyped,
    SearchConditionV0ToJSON,
} from './SearchConditionV0';

/**
 * 
 * @export
 * @interface HeaderExportQueryV0
 */
export interface HeaderExportQueryV0 {
    /**
     * 
     * @type {Array<SearchConditionV0>}
     * @memberof HeaderExportQueryV0
     */
    conditions?: Array<SearchConditionV0>;
    /**
     * 
     * @type {Date}
     * @memberof HeaderExportQueryV0
     */
    end: Date;
    /**
     * 
     * @type {Date}
     * @memberof HeaderExportQueryV0
     */
    start: Date;
}

/**
 * Check if a given object implements the HeaderExportQueryV0 interface.
 */
export function instanceOfHeaderExportQueryV0(value: object): value is HeaderExportQueryV0 {
    if (!('end' in value) || value['end'] === undefined) return false;
    if (!('start' in value) || value['start'] === undefined) return false;
    return true;
}

export function HeaderExportQueryV0FromJSON(json: any): HeaderExportQueryV0 {
    return HeaderExportQueryV0FromJSONTyped(json, false);
}

export function HeaderExportQueryV0FromJSONTyped(json: any, ignoreDiscriminator: boolean): HeaderExportQueryV0 {
    if (json == null) {
        return json;
    }
    return {
        
        'conditions': json['conditions'] == null ? undefined : ((json['conditions'] as Array<any>).map(SearchConditionV0FromJSON)),
        'end': (new Date(json['end'])),
        'start': (new Date(json['start'])),
    };
}

export function HeaderExportQueryV0ToJSON(value?: HeaderExportQueryV0 | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'conditions': value['conditions'] == null ? undefined : ((value['conditions'] as Array<any>).map(SearchConditionV0ToJSON)),
        'end': ((value['end']).toISOString()),
        'start': ((value['start']).toISOString()),
    };
}

