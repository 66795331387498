/* tslint:disable */
/* eslint-disable */
/**
 * HENNGE Email Archive API
 * HENNGE Email Archive administration and search API.
 *
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { AccessPolicyIdV0 } from './AccessPolicyIdV0';
import {
    AccessPolicyIdV0FromJSON,
    AccessPolicyIdV0FromJSONTyped,
    AccessPolicyIdV0ToJSON,
} from './AccessPolicyIdV0';

/**
 * 
 * @export
 * @interface NamedAccessPolicyV0
 */
export interface NamedAccessPolicyV0 {
    /**
     * 
     * @type {AccessPolicyIdV0}
     * @memberof NamedAccessPolicyV0
     */
    id: AccessPolicyIdV0;
    /**
     * 
     * @type {string}
     * @memberof NamedAccessPolicyV0
     */
    type: NamedAccessPolicyV0TypeEnum;
}


/**
 * @export
 */
export const NamedAccessPolicyV0TypeEnum = {
    Named: 'named'
} as const;
export type NamedAccessPolicyV0TypeEnum = typeof NamedAccessPolicyV0TypeEnum[keyof typeof NamedAccessPolicyV0TypeEnum];


/**
 * Check if a given object implements the NamedAccessPolicyV0 interface.
 */
export function instanceOfNamedAccessPolicyV0(value: object): value is NamedAccessPolicyV0 {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('type' in value) || value['type'] === undefined) return false;
    return true;
}

export function NamedAccessPolicyV0FromJSON(json: any): NamedAccessPolicyV0 {
    return NamedAccessPolicyV0FromJSONTyped(json, false);
}

export function NamedAccessPolicyV0FromJSONTyped(json: any, ignoreDiscriminator: boolean): NamedAccessPolicyV0 {
    if (json == null) {
        return json;
    }
    return {
        
        'id': AccessPolicyIdV0FromJSON(json['id']),
        'type': json['type'],
    };
}

export function NamedAccessPolicyV0ToJSON(value?: NamedAccessPolicyV0 | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': AccessPolicyIdV0ToJSON(value['id']),
        'type': value['type'],
    };
}

