/* tslint:disable */
/* eslint-disable */
/**
 * HENNGE Email Archive API
 * HENNGE Email Archive administration and search API.
 *
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { AddressDomainSearchPolicyRuleV0 } from './AddressDomainSearchPolicyRuleV0';
import {
    instanceOfAddressDomainSearchPolicyRuleV0,
    AddressDomainSearchPolicyRuleV0FromJSON,
    AddressDomainSearchPolicyRuleV0FromJSONTyped,
    AddressDomainSearchPolicyRuleV0ToJSON,
} from './AddressDomainSearchPolicyRuleV0';
import type { AddressSearchPolicyRuleV0 } from './AddressSearchPolicyRuleV0';
import {
    instanceOfAddressSearchPolicyRuleV0,
    AddressSearchPolicyRuleV0FromJSON,
    AddressSearchPolicyRuleV0FromJSONTyped,
    AddressSearchPolicyRuleV0ToJSON,
} from './AddressSearchPolicyRuleV0';
import type { ArchivedPeriodSearchPolicyRuleV0 } from './ArchivedPeriodSearchPolicyRuleV0';
import {
    instanceOfArchivedPeriodSearchPolicyRuleV0,
    ArchivedPeriodSearchPolicyRuleV0FromJSON,
    ArchivedPeriodSearchPolicyRuleV0FromJSONTyped,
    ArchivedPeriodSearchPolicyRuleV0ToJSON,
} from './ArchivedPeriodSearchPolicyRuleV0';
import type { AttachmentSearchPolicyRuleV0 } from './AttachmentSearchPolicyRuleV0';
import {
    instanceOfAttachmentSearchPolicyRuleV0,
    AttachmentSearchPolicyRuleV0FromJSON,
    AttachmentSearchPolicyRuleV0FromJSONTyped,
    AttachmentSearchPolicyRuleV0ToJSON,
} from './AttachmentSearchPolicyRuleV0';
import type { KeywordSearchPolicyRuleV0 } from './KeywordSearchPolicyRuleV0';
import {
    instanceOfKeywordSearchPolicyRuleV0,
    KeywordSearchPolicyRuleV0FromJSON,
    KeywordSearchPolicyRuleV0FromJSONTyped,
    KeywordSearchPolicyRuleV0ToJSON,
} from './KeywordSearchPolicyRuleV0';

/**
 * @type SearchPolicyRulesV0
 * 
 * @export
 */
export type SearchPolicyRulesV0 = { field: 'address' } & AddressSearchPolicyRuleV0 | { field: 'address_domain' } & AddressDomainSearchPolicyRuleV0 | { field: 'has_attachment' } & AttachmentSearchPolicyRuleV0 | { field: 'keyword' } & KeywordSearchPolicyRuleV0 | { field: 'period' } & ArchivedPeriodSearchPolicyRuleV0;

export function SearchPolicyRulesV0FromJSON(json: any): SearchPolicyRulesV0 {
    return SearchPolicyRulesV0FromJSONTyped(json, false);
}

export function SearchPolicyRulesV0FromJSONTyped(json: any, ignoreDiscriminator: boolean): SearchPolicyRulesV0 {
    if (json == null) {
        return json;
    }
    switch (json['field']) {
        case 'address':
            return Object.assign({}, AddressSearchPolicyRuleV0FromJSONTyped(json, true), { field: 'address' } as const);
        case 'address_domain':
            return Object.assign({}, AddressDomainSearchPolicyRuleV0FromJSONTyped(json, true), { field: 'address_domain' } as const);
        case 'has_attachment':
            return Object.assign({}, AttachmentSearchPolicyRuleV0FromJSONTyped(json, true), { field: 'has_attachment' } as const);
        case 'keyword':
            return Object.assign({}, KeywordSearchPolicyRuleV0FromJSONTyped(json, true), { field: 'keyword' } as const);
        case 'period':
            return Object.assign({}, ArchivedPeriodSearchPolicyRuleV0FromJSONTyped(json, true), { field: 'period' } as const);
        default:
            throw new Error(`No variant of SearchPolicyRulesV0 exists with 'field=${json['field']}'`);
    }
}

export function SearchPolicyRulesV0ToJSON(value?: SearchPolicyRulesV0 | null): any {
    if (value == null) {
        return value;
    }
    switch (value['field']) {
        case 'address':
            return AddressSearchPolicyRuleV0ToJSON(value);
        case 'address_domain':
            return AddressDomainSearchPolicyRuleV0ToJSON(value);
        case 'has_attachment':
            return AttachmentSearchPolicyRuleV0ToJSON(value);
        case 'keyword':
            return KeywordSearchPolicyRuleV0ToJSON(value);
        case 'period':
            return ArchivedPeriodSearchPolicyRuleV0ToJSON(value);
        default:
            throw new Error(`No variant of SearchPolicyRulesV0 exists with 'field=${value['field']}'`);
    }

}

