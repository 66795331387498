/* tslint:disable */
/* eslint-disable */
/**
 * HENNGE Email Archive API
 * HENNGE Email Archive administration and search API.
 *
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { SearchPolicyRulesV0 } from './SearchPolicyRulesV0';
import {
    SearchPolicyRulesV0FromJSON,
    SearchPolicyRulesV0FromJSONTyped,
    SearchPolicyRulesV0ToJSON,
} from './SearchPolicyRulesV0';

/**
 * Values returned by api.
 * @export
 * @interface CustomSearchPolicyDetailsV0
 */
export interface CustomSearchPolicyDetailsV0 {
    /**
     * 
     * @type {Date}
     * @memberof CustomSearchPolicyDetailsV0
     */
    createdAt: Date;
    /**
     * 
     * @type {string}
     * @memberof CustomSearchPolicyDetailsV0
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof CustomSearchPolicyDetailsV0
     */
    name: string;
    /**
     * 
     * @type {Array<SearchPolicyRulesV0>}
     * @memberof CustomSearchPolicyDetailsV0
     */
    rules: Array<SearchPolicyRulesV0>;
    /**
     * 
     * @type {string}
     * @memberof CustomSearchPolicyDetailsV0
     */
    type: CustomSearchPolicyDetailsV0TypeEnum;
    /**
     * 
     * @type {Date}
     * @memberof CustomSearchPolicyDetailsV0
     */
    updatedAt: Date;
    /**
     * 
     * @type {Array<string>}
     * @memberof CustomSearchPolicyDetailsV0
     */
    userIds: Array<string>;
}


/**
 * @export
 */
export const CustomSearchPolicyDetailsV0TypeEnum = {
    Custom: 'custom'
} as const;
export type CustomSearchPolicyDetailsV0TypeEnum = typeof CustomSearchPolicyDetailsV0TypeEnum[keyof typeof CustomSearchPolicyDetailsV0TypeEnum];


/**
 * Check if a given object implements the CustomSearchPolicyDetailsV0 interface.
 */
export function instanceOfCustomSearchPolicyDetailsV0(value: object): value is CustomSearchPolicyDetailsV0 {
    if (!('createdAt' in value) || value['createdAt'] === undefined) return false;
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('name' in value) || value['name'] === undefined) return false;
    if (!('rules' in value) || value['rules'] === undefined) return false;
    if (!('type' in value) || value['type'] === undefined) return false;
    if (!('updatedAt' in value) || value['updatedAt'] === undefined) return false;
    if (!('userIds' in value) || value['userIds'] === undefined) return false;
    return true;
}

export function CustomSearchPolicyDetailsV0FromJSON(json: any): CustomSearchPolicyDetailsV0 {
    return CustomSearchPolicyDetailsV0FromJSONTyped(json, false);
}

export function CustomSearchPolicyDetailsV0FromJSONTyped(json: any, ignoreDiscriminator: boolean): CustomSearchPolicyDetailsV0 {
    if (json == null) {
        return json;
    }
    return {
        
        'createdAt': (new Date(json['created_at'])),
        'id': json['id'],
        'name': json['name'],
        'rules': ((json['rules'] as Array<any>).map(SearchPolicyRulesV0FromJSON)),
        'type': json['type'],
        'updatedAt': (new Date(json['updated_at'])),
        'userIds': json['user_ids'],
    };
}

export function CustomSearchPolicyDetailsV0ToJSON(value?: CustomSearchPolicyDetailsV0 | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'created_at': ((value['createdAt']).toISOString()),
        'id': value['id'],
        'name': value['name'],
        'rules': ((value['rules'] as Array<any>).map(SearchPolicyRulesV0ToJSON)),
        'type': value['type'],
        'updated_at': ((value['updatedAt']).toISOString()),
        'user_ids': value['userIds'],
    };
}

