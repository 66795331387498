/* tslint:disable */
/* eslint-disable */
/**
 * HENNGE Email Archive API
 * HENNGE Email Archive administration and search API.
 *
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface EnvelopeFromEnvelopeToSearchConditionV0
 */
export interface EnvelopeFromEnvelopeToSearchConditionV0 {
    /**
     * 
     * @type {string}
     * @memberof EnvelopeFromEnvelopeToSearchConditionV0
     */
    field: EnvelopeFromEnvelopeToSearchConditionV0FieldEnum;
    /**
     * 
     * @type {string}
     * @memberof EnvelopeFromEnvelopeToSearchConditionV0
     */
    type: EnvelopeFromEnvelopeToSearchConditionV0TypeEnum;
    /**
     * 
     * @type {Array<string>}
     * @memberof EnvelopeFromEnvelopeToSearchConditionV0
     */
    values: Array<string>;
}


/**
 * @export
 */
export const EnvelopeFromEnvelopeToSearchConditionV0FieldEnum = {
    EnvelopeFromEnvelopeTo: 'envelope_from_envelope_to'
} as const;
export type EnvelopeFromEnvelopeToSearchConditionV0FieldEnum = typeof EnvelopeFromEnvelopeToSearchConditionV0FieldEnum[keyof typeof EnvelopeFromEnvelopeToSearchConditionV0FieldEnum];

/**
 * @export
 */
export const EnvelopeFromEnvelopeToSearchConditionV0TypeEnum = {
    Contain: 'contain',
    Match: 'match',
    NotContain: 'not_contain',
    NotMatch: 'not_match'
} as const;
export type EnvelopeFromEnvelopeToSearchConditionV0TypeEnum = typeof EnvelopeFromEnvelopeToSearchConditionV0TypeEnum[keyof typeof EnvelopeFromEnvelopeToSearchConditionV0TypeEnum];


/**
 * Check if a given object implements the EnvelopeFromEnvelopeToSearchConditionV0 interface.
 */
export function instanceOfEnvelopeFromEnvelopeToSearchConditionV0(value: object): value is EnvelopeFromEnvelopeToSearchConditionV0 {
    if (!('field' in value) || value['field'] === undefined) return false;
    if (!('type' in value) || value['type'] === undefined) return false;
    if (!('values' in value) || value['values'] === undefined) return false;
    return true;
}

export function EnvelopeFromEnvelopeToSearchConditionV0FromJSON(json: any): EnvelopeFromEnvelopeToSearchConditionV0 {
    return EnvelopeFromEnvelopeToSearchConditionV0FromJSONTyped(json, false);
}

export function EnvelopeFromEnvelopeToSearchConditionV0FromJSONTyped(json: any, ignoreDiscriminator: boolean): EnvelopeFromEnvelopeToSearchConditionV0 {
    if (json == null) {
        return json;
    }
    return {
        
        'field': json['field'],
        'type': json['type'],
        'values': json['values'],
    };
}

export function EnvelopeFromEnvelopeToSearchConditionV0ToJSON(value?: EnvelopeFromEnvelopeToSearchConditionV0 | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'field': value['field'],
        'type': value['type'],
        'values': value['values'],
    };
}

