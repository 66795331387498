<template>
  <GlobalHeader />
  <div class="Wrapper">
    <main class="DefaultTemplate">
      <slot />
    </main>
  </div>

  <BaseLoader v-if="loader.isLoading" fullscreen />
  <SnackbarsContainer />
</template>

<script setup lang="ts">
const { loader } = useStores('loader');

defineSlots<{
  default(): void;
}>();
</script>

<style scoped lang="scss">
.Wrapper {
  position: relative;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
  height: calc(100vh - #{$header-height});
  padding-top: $header-height;
}

.DefaultTemplate {
  flex: 1 1 0px;
  width: auto;
}
</style>
