/* tslint:disable */
/* eslint-disable */
/**
 * HENNGE Email Archive API
 * HENNGE Email Archive administration and search API.
 *
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AttachmentSearchConditionV0
 */
export interface AttachmentSearchConditionV0 {
    /**
     * 
     * @type {string}
     * @memberof AttachmentSearchConditionV0
     */
    field: AttachmentSearchConditionV0FieldEnum;
    /**
     * 
     * @type {string}
     * @memberof AttachmentSearchConditionV0
     */
    type: AttachmentSearchConditionV0TypeEnum;
}


/**
 * @export
 */
export const AttachmentSearchConditionV0FieldEnum = {
    HasAttachment: 'has_attachment'
} as const;
export type AttachmentSearchConditionV0FieldEnum = typeof AttachmentSearchConditionV0FieldEnum[keyof typeof AttachmentSearchConditionV0FieldEnum];

/**
 * @export
 */
export const AttachmentSearchConditionV0TypeEnum = {
    Exist: 'exist',
    NotExist: 'not_exist'
} as const;
export type AttachmentSearchConditionV0TypeEnum = typeof AttachmentSearchConditionV0TypeEnum[keyof typeof AttachmentSearchConditionV0TypeEnum];


/**
 * Check if a given object implements the AttachmentSearchConditionV0 interface.
 */
export function instanceOfAttachmentSearchConditionV0(value: object): value is AttachmentSearchConditionV0 {
    if (!('field' in value) || value['field'] === undefined) return false;
    if (!('type' in value) || value['type'] === undefined) return false;
    return true;
}

export function AttachmentSearchConditionV0FromJSON(json: any): AttachmentSearchConditionV0 {
    return AttachmentSearchConditionV0FromJSONTyped(json, false);
}

export function AttachmentSearchConditionV0FromJSONTyped(json: any, ignoreDiscriminator: boolean): AttachmentSearchConditionV0 {
    if (json == null) {
        return json;
    }
    return {
        
        'field': json['field'],
        'type': json['type'],
    };
}

export function AttachmentSearchConditionV0ToJSON(value?: AttachmentSearchConditionV0 | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'field': value['field'],
        'type': value['type'],
    };
}

