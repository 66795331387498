<template>
  <section class="DomainField">
    <h2 class="DomainFieldTitle">{{ $t('login.enterDomainName') }}</h2>

    <div class="DomainFieldInner">
      <TextField
        v-model="domain"
        class="DomainInput"
        :error="errorMessage"
        name="domainName"
        type="text"
        alt
        size="lg"
        placeholder="placeholders.inputDomainNamePlaceholder"
        @submit="submitHandler"
      />

      <BaseAction type="submit" size="lg" @click="submitHandler">
        {{ $t('actionText.login') }}
      </BaseAction>
    </div>
  </section>
</template>

<script setup lang="ts">
import type { TranslationKey } from '@/locales';

const emit = defineEmits<{
  submit: [domain: string];
}>();

const domain = defineModel<string>({ required: true });

const errorMessage = ref<TranslationKey | undefined>();

function validateDomain(domain: string) {
  const reg = /^([a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,6}(:[0-9]{1,5})?(\/.*)?)$/;
  return reg.test(String(domain).toLowerCase());
}

const isValidDomain = computed<boolean>(() => validateDomain(domain.value));

function submitHandler() {
  if (isValidDomain.value) {
    errorMessage.value = undefined;
    emit('submit', domain.value);
  } else {
    errorMessage.value = 'validation.domainError';
  }
}
</script>

<style scoped lang="scss">
.DomainField {
  display: flex;
  flex-flow: column wrap;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.DomainFieldTitle {
  color: black(87);
  font-size: 28px;
  line-height: 1.2;
  margin-bottom: 36px;
}

.DomainFieldInner {
  display: flex;
  flex-flow: row wrap;
  align-items: flex-start;
  justify-content: center;
  gap: 8px;
}

:deep(.DomainInput) {
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  width: 320px;
}

:deep(.HelpMessage) {
  position: absolute;
}
</style>
