import App from './App.vue';

// plugins
import createBootstrap from './plugins/bootstrap-vue';
import router from './router';
import { piniaInstance as pinia } from './store';

import { DatePicker, setupCalendar } from 'v-calendar';
import VueGtagPlugin from 'vue-gtag';

import './plugins/dayjs';

import { vFocusTrap, vOnClickOutside, vScrollLock } from '@/directives';
import PackageInfo from '../package.json';
import i18n, { vCalendarConfig } from './locales';

// Sentry
import * as Sentry from '@sentry/vue';

// styles
import 'normalize.css';
import 'v-calendar/style.css';

const app = createApp(App)
  .use(pinia) // Install the store before everything else! including router
  .use(router)
  .use(nobitaHead)
  .use(i18n)
  .use(
    VueGtagPlugin,
    {
      enabled: !!import.meta.env.VITE_NOBITA_GA,
      disableScriptLoad: !import.meta.env.VITE_NOBITA_GA,
      config: {
        id: import.meta.env.VITE_NOBITA_GA,
      },
    },
    router,
  )
  .use(createBootstrap())
  .use(setupCalendar, vCalendarConfig)
  .component('VDatePicker', DatePicker)
  .directive('click-outside', vOnClickOutside)
  .directive('scroll-lock', vScrollLock)
  .directive('focus-trap', vFocusTrap);

logger.info(`Running in mode '${import.meta.env.VITE_NOBITA_ENV}'`);

if (import.meta.env.VITE_SENTRY_ENABLED === 'true') {
  // This should be true only when `vite build` was run, regardless of env
  const tracesSampleRate = parseFloat(import.meta.env.VITE_SENTRY_TRACES_SAMPLE_RATE);

  Sentry.init({
    app,
    environment: import.meta.env.VITE_NOBITA_ENV,
    dsn: import.meta.env.VITE_APP_SENTRY_DSN,
    release: PackageInfo.version ?? undefined,

    integrations: [
      Sentry.browserTracingIntegration({
        router,
      }),
    ],

    tracesSampleRate: isNaN(tracesSampleRate) ? 0.1 : tracesSampleRate,

    tracePropagationTargets: [
      'localhost',
      'archive.nobita.prd.demodesu.com',
      'staging.nobita.demodesu.com',
      'archive.hennge.com',
      /^\//,
    ],
  });
}

app.mount('#app');
