/* tslint:disable */
/* eslint-disable */
/**
 * HENNGE Email Archive API
 * HENNGE Email Archive administration and search API.
 *
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AddressSearchPolicyRuleV0
 */
export interface AddressSearchPolicyRuleV0 {
    /**
     * 
     * @type {string}
     * @memberof AddressSearchPolicyRuleV0
     */
    effect: AddressSearchPolicyRuleV0EffectEnum;
    /**
     * 
     * @type {string}
     * @memberof AddressSearchPolicyRuleV0
     */
    field: AddressSearchPolicyRuleV0FieldEnum;
    /**
     * 
     * @type {Array<string>}
     * @memberof AddressSearchPolicyRuleV0
     */
    values: Array<string>;
}


/**
 * @export
 */
export const AddressSearchPolicyRuleV0EffectEnum = {
    Allow: 'allow',
    Deny: 'deny'
} as const;
export type AddressSearchPolicyRuleV0EffectEnum = typeof AddressSearchPolicyRuleV0EffectEnum[keyof typeof AddressSearchPolicyRuleV0EffectEnum];

/**
 * @export
 */
export const AddressSearchPolicyRuleV0FieldEnum = {
    Address: 'address'
} as const;
export type AddressSearchPolicyRuleV0FieldEnum = typeof AddressSearchPolicyRuleV0FieldEnum[keyof typeof AddressSearchPolicyRuleV0FieldEnum];


/**
 * Check if a given object implements the AddressSearchPolicyRuleV0 interface.
 */
export function instanceOfAddressSearchPolicyRuleV0(value: object): value is AddressSearchPolicyRuleV0 {
    if (!('effect' in value) || value['effect'] === undefined) return false;
    if (!('field' in value) || value['field'] === undefined) return false;
    if (!('values' in value) || value['values'] === undefined) return false;
    return true;
}

export function AddressSearchPolicyRuleV0FromJSON(json: any): AddressSearchPolicyRuleV0 {
    return AddressSearchPolicyRuleV0FromJSONTyped(json, false);
}

export function AddressSearchPolicyRuleV0FromJSONTyped(json: any, ignoreDiscriminator: boolean): AddressSearchPolicyRuleV0 {
    if (json == null) {
        return json;
    }
    return {
        
        'effect': json['effect'],
        'field': json['field'],
        'values': json['values'],
    };
}

export function AddressSearchPolicyRuleV0ToJSON(value?: AddressSearchPolicyRuleV0 | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'effect': value['effect'],
        'field': value['field'],
        'values': value['values'],
    };
}

