<template>
  <li class="NavSection">
    <dl class="Section">
      <dt class="Header">
        <h2 class="HeaderTitle">
          <BaseIcon class="IconTitle">
            <NobitaIconArchiveOutlined v-if="section.id === 'emailArchive'" />
            <NobitaIconListOutlined v-else-if="section.id === 'jobList'" />
            <NobitaIconSettingsOutlined v-else />
          </BaseIcon>
          {{ $t(section.title) }}
        </h2>
      </dt>
      <dd class="Content">
        <ul class="SectionMenu">
          <BaseNavItem
            v-for="(subitem, idx) in section.items.filter((section) => section.isShown)"
            :key="`nav-menu-item-${index}-${idx}`"
            :item="subitem"
          />
        </ul>
      </dd>
    </dl>
  </li>
</template>

<script setup lang="ts">
import type { NavSection } from '@/types/Nav';

export type Props = {
  index: number;
  section: NavSection;
};

defineProps<Props>();
</script>

<style scoped lang="scss">
.NavSection {
  padding: 12px 0;
}

.Header {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: black(60);
  font-size: 14px;
  line-height: 1;
  padding: 8px 16px 8px 20px;
  white-space: nowrap;
  background-color: transparent;
}

.HeaderTitle {
  display: flex;
  align-items: center;
  margin: 0;
  font-size: 14px;
  line-height: 1;
  font-weight: bold;
}

.IconTitle {
  width: 20px;
  height: 20px;
  left: 22px;
  margin-right: 8px;
  fill: black(87);
}

.Section,
.Content {
  margin: 0;
}

.SectionMenu {
  padding: 0;
  list-style-type: none;
}
</style>
