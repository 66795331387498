/* tslint:disable */
/* eslint-disable */
/**
 * HENNGE Email Archive API
 * HENNGE Email Archive administration and search API.
 *
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ArchiveStatisticsV0
 */
export interface ArchiveStatisticsV0 {
    /**
     * Total count of all mails in the archive for the tenant, as recorded by the last statistics update.
     * @type {number}
     * @memberof ArchiveStatisticsV0
     */
    archivedMailCount: number;
    /**
     * Total size of all the mails in the archive for the tenant in megabytes, as recorded by the last statistics update.
     * @type {number}
     * @memberof ArchiveStatisticsV0
     */
    archivedMailMegabytes: number;
    /**
     * date when the statistics were last updated in full-date notation as defined by RFC 3339.
     * @type {string}
     * @memberof ArchiveStatisticsV0
     */
    lastUpdated: string;
}

/**
 * Check if a given object implements the ArchiveStatisticsV0 interface.
 */
export function instanceOfArchiveStatisticsV0(value: object): value is ArchiveStatisticsV0 {
    if (!('archivedMailCount' in value) || value['archivedMailCount'] === undefined) return false;
    if (!('archivedMailMegabytes' in value) || value['archivedMailMegabytes'] === undefined) return false;
    if (!('lastUpdated' in value) || value['lastUpdated'] === undefined) return false;
    return true;
}

export function ArchiveStatisticsV0FromJSON(json: any): ArchiveStatisticsV0 {
    return ArchiveStatisticsV0FromJSONTyped(json, false);
}

export function ArchiveStatisticsV0FromJSONTyped(json: any, ignoreDiscriminator: boolean): ArchiveStatisticsV0 {
    if (json == null) {
        return json;
    }
    return {
        
        'archivedMailCount': json['archived_mail_count'],
        'archivedMailMegabytes': json['archived_mail_megabytes'],
        'lastUpdated': json['last_updated'],
    };
}

export function ArchiveStatisticsV0ToJSON(value?: ArchiveStatisticsV0 | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'archived_mail_count': value['archivedMailCount'],
        'archived_mail_megabytes': value['archivedMailMegabytes'],
        'last_updated': value['lastUpdated'],
    };
}

