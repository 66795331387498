/* tslint:disable */
/* eslint-disable */
/**
 * HENNGE Email Archive API
 * HENNGE Email Archive administration and search API.
 *
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { UserV0 } from './UserV0';
import {
    UserV0FromJSON,
    UserV0FromJSONTyped,
    UserV0ToJSON,
} from './UserV0';

/**
 * List of users returned by api.
 * @export
 * @interface UsersV0
 */
export interface UsersV0 {
    /**
     * 
     * @type {Array<UserV0>}
     * @memberof UsersV0
     */
    users: Array<UserV0>;
}

/**
 * Check if a given object implements the UsersV0 interface.
 */
export function instanceOfUsersV0(value: object): value is UsersV0 {
    if (!('users' in value) || value['users'] === undefined) return false;
    return true;
}

export function UsersV0FromJSON(json: any): UsersV0 {
    return UsersV0FromJSONTyped(json, false);
}

export function UsersV0FromJSONTyped(json: any, ignoreDiscriminator: boolean): UsersV0 {
    if (json == null) {
        return json;
    }
    return {
        
        'users': ((json['users'] as Array<any>).map(UserV0FromJSON)),
    };
}

export function UsersV0ToJSON(value?: UsersV0 | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'users': ((value['users'] as Array<any>).map(UserV0ToJSON)),
    };
}

