/* tslint:disable */
/* eslint-disable */
/**
 * HENNGE Email Archive API
 * HENNGE Email Archive administration and search API.
 *
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ErrorInfoV0
 */
export interface ErrorInfoV0 {
    /**
     * 
     * @type {number}
     * @memberof ErrorInfoV0
     */
    code: number;
    /**
     * 
     * @type {object}
     * @memberof ErrorInfoV0
     */
    context?: object | null;
    /**
     * 
     * @type {string}
     * @memberof ErrorInfoV0
     */
    message: string;
}

/**
 * Check if a given object implements the ErrorInfoV0 interface.
 */
export function instanceOfErrorInfoV0(value: object): value is ErrorInfoV0 {
    if (!('code' in value) || value['code'] === undefined) return false;
    if (!('message' in value) || value['message'] === undefined) return false;
    return true;
}

export function ErrorInfoV0FromJSON(json: any): ErrorInfoV0 {
    return ErrorInfoV0FromJSONTyped(json, false);
}

export function ErrorInfoV0FromJSONTyped(json: any, ignoreDiscriminator: boolean): ErrorInfoV0 {
    if (json == null) {
        return json;
    }
    return {
        
        'code': json['code'],
        'context': json['context'] == null ? undefined : json['context'],
        'message': json['message'],
    };
}

export function ErrorInfoV0ToJSON(value?: ErrorInfoV0 | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'code': value['code'],
        'context': value['context'],
        'message': value['message'],
    };
}

