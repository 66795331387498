/* tslint:disable */
/* eslint-disable */
/**
 * HENNGE Email Archive API
 * HENNGE Email Archive administration and search API.
 *
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface URLResponseV0
 */
export interface URLResponseV0 {
    /**
     * 
     * @type {string}
     * @memberof URLResponseV0
     */
    url: string;
}

/**
 * Check if a given object implements the URLResponseV0 interface.
 */
export function instanceOfURLResponseV0(value: object): value is URLResponseV0 {
    if (!('url' in value) || value['url'] === undefined) return false;
    return true;
}

export function URLResponseV0FromJSON(json: any): URLResponseV0 {
    return URLResponseV0FromJSONTyped(json, false);
}

export function URLResponseV0FromJSONTyped(json: any, ignoreDiscriminator: boolean): URLResponseV0 {
    if (json == null) {
        return json;
    }
    return {
        
        'url': json['url'],
    };
}

export function URLResponseV0ToJSON(value?: URLResponseV0 | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'url': value['url'],
    };
}

