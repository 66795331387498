/* tslint:disable */
/* eslint-disable */
/**
 * HENNGE Email Archive API
 * HENNGE Email Archive administration and search API.
 *
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * Values returned by api.
 * @export
 * @interface SystemSearchPolicyDetailsV0
 */
export interface SystemSearchPolicyDetailsV0 {
    /**
     * 
     * @type {string}
     * @memberof SystemSearchPolicyDetailsV0
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof SystemSearchPolicyDetailsV0
     */
    type: SystemSearchPolicyDetailsV0TypeEnum;
    /**
     * 
     * @type {Array<string>}
     * @memberof SystemSearchPolicyDetailsV0
     */
    userIds: Array<string>;
}


/**
 * @export
 */
export const SystemSearchPolicyDetailsV0TypeEnum = {
    System: 'system'
} as const;
export type SystemSearchPolicyDetailsV0TypeEnum = typeof SystemSearchPolicyDetailsV0TypeEnum[keyof typeof SystemSearchPolicyDetailsV0TypeEnum];


/**
 * Check if a given object implements the SystemSearchPolicyDetailsV0 interface.
 */
export function instanceOfSystemSearchPolicyDetailsV0(value: object): value is SystemSearchPolicyDetailsV0 {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('type' in value) || value['type'] === undefined) return false;
    if (!('userIds' in value) || value['userIds'] === undefined) return false;
    return true;
}

export function SystemSearchPolicyDetailsV0FromJSON(json: any): SystemSearchPolicyDetailsV0 {
    return SystemSearchPolicyDetailsV0FromJSONTyped(json, false);
}

export function SystemSearchPolicyDetailsV0FromJSONTyped(json: any, ignoreDiscriminator: boolean): SystemSearchPolicyDetailsV0 {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'type': json['type'],
        'userIds': json['user_ids'],
    };
}

export function SystemSearchPolicyDetailsV0ToJSON(value?: SystemSearchPolicyDetailsV0 | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'type': value['type'],
        'user_ids': value['userIds'],
    };
}

