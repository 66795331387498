/* tslint:disable */
/* eslint-disable */
/**
 * HENNGE Email Archive API
 * HENNGE Email Archive administration and search API.
 *
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FromSearchConditionV0
 */
export interface FromSearchConditionV0 {
    /**
     * 
     * @type {string}
     * @memberof FromSearchConditionV0
     */
    field: FromSearchConditionV0FieldEnum;
    /**
     * 
     * @type {string}
     * @memberof FromSearchConditionV0
     */
    type: FromSearchConditionV0TypeEnum;
    /**
     * 
     * @type {Array<string>}
     * @memberof FromSearchConditionV0
     */
    values: Array<string>;
}


/**
 * @export
 */
export const FromSearchConditionV0FieldEnum = {
    From: 'from'
} as const;
export type FromSearchConditionV0FieldEnum = typeof FromSearchConditionV0FieldEnum[keyof typeof FromSearchConditionV0FieldEnum];

/**
 * @export
 */
export const FromSearchConditionV0TypeEnum = {
    Contain: 'contain',
    Match: 'match',
    NotContain: 'not_contain',
    NotMatch: 'not_match'
} as const;
export type FromSearchConditionV0TypeEnum = typeof FromSearchConditionV0TypeEnum[keyof typeof FromSearchConditionV0TypeEnum];


/**
 * Check if a given object implements the FromSearchConditionV0 interface.
 */
export function instanceOfFromSearchConditionV0(value: object): value is FromSearchConditionV0 {
    if (!('field' in value) || value['field'] === undefined) return false;
    if (!('type' in value) || value['type'] === undefined) return false;
    if (!('values' in value) || value['values'] === undefined) return false;
    return true;
}

export function FromSearchConditionV0FromJSON(json: any): FromSearchConditionV0 {
    return FromSearchConditionV0FromJSONTyped(json, false);
}

export function FromSearchConditionV0FromJSONTyped(json: any, ignoreDiscriminator: boolean): FromSearchConditionV0 {
    if (json == null) {
        return json;
    }
    return {
        
        'field': json['field'],
        'type': json['type'],
        'values': json['values'],
    };
}

export function FromSearchConditionV0ToJSON(value?: FromSearchConditionV0 | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'field': value['field'],
        'type': value['type'],
        'values': value['values'],
    };
}

