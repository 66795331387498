/* tslint:disable */
/* eslint-disable */
/**
 * HENNGE Email Archive API
 * HENNGE Email Archive administration and search API.
 *
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface TokenResponseV0
 */
export interface TokenResponseV0 {
    /**
     * 
     * @type {string}
     * @memberof TokenResponseV0
     */
    accessToken: string;
    /**
     * 
     * @type {string}
     * @memberof TokenResponseV0
     */
    loginHint?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TokenResponseV0
     */
    state?: string | null;
}

/**
 * Check if a given object implements the TokenResponseV0 interface.
 */
export function instanceOfTokenResponseV0(value: object): value is TokenResponseV0 {
    if (!('accessToken' in value) || value['accessToken'] === undefined) return false;
    return true;
}

export function TokenResponseV0FromJSON(json: any): TokenResponseV0 {
    return TokenResponseV0FromJSONTyped(json, false);
}

export function TokenResponseV0FromJSONTyped(json: any, ignoreDiscriminator: boolean): TokenResponseV0 {
    if (json == null) {
        return json;
    }
    return {
        
        'accessToken': json['access_token'],
        'loginHint': json['login_hint'] == null ? undefined : json['login_hint'],
        'state': json['state'] == null ? undefined : json['state'],
    };
}

export function TokenResponseV0ToJSON(value?: TokenResponseV0 | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'access_token': value['accessToken'],
        'login_hint': value['loginHint'],
        'state': value['state'],
    };
}

