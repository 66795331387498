/* tslint:disable */
/* eslint-disable */
/**
 * HENNGE Email Archive API
 * HENNGE Email Archive administration and search API.
 *
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { SearchPolicyInListV0 } from './SearchPolicyInListV0';
import {
    SearchPolicyInListV0FromJSON,
    SearchPolicyInListV0FromJSONTyped,
    SearchPolicyInListV0ToJSON,
} from './SearchPolicyInListV0';

/**
 * List of search policy ids available to a specific domain.
 * @export
 * @interface SearchPoliciesV0
 */
export interface SearchPoliciesV0 {
    /**
     * 
     * @type {Array<SearchPolicyInListV0>}
     * @memberof SearchPoliciesV0
     */
    searchPolicies: Array<SearchPolicyInListV0>;
}

/**
 * Check if a given object implements the SearchPoliciesV0 interface.
 */
export function instanceOfSearchPoliciesV0(value: object): value is SearchPoliciesV0 {
    if (!('searchPolicies' in value) || value['searchPolicies'] === undefined) return false;
    return true;
}

export function SearchPoliciesV0FromJSON(json: any): SearchPoliciesV0 {
    return SearchPoliciesV0FromJSONTyped(json, false);
}

export function SearchPoliciesV0FromJSONTyped(json: any, ignoreDiscriminator: boolean): SearchPoliciesV0 {
    if (json == null) {
        return json;
    }
    return {
        
        'searchPolicies': ((json['search_policies'] as Array<any>).map(SearchPolicyInListV0FromJSON)),
    };
}

export function SearchPoliciesV0ToJSON(value?: SearchPoliciesV0 | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'search_policies': ((value['searchPolicies'] as Array<any>).map(SearchPolicyInListV0ToJSON)),
    };
}

