/* tslint:disable */
/* eslint-disable */
/**
 * HENNGE Email Archive API
 * HENNGE Email Archive administration and search API.
 *
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AccessPolicyArchiveSearchOptionV0
 */
export interface AccessPolicyArchiveSearchOptionV0 {
    /**
     * 
     * @type {string}
     * @memberof AccessPolicyArchiveSearchOptionV0
     */
    id: AccessPolicyArchiveSearchOptionV0IdEnum;
    /**
     * 
     * @type {string}
     * @memberof AccessPolicyArchiveSearchOptionV0
     */
    value: AccessPolicyArchiveSearchOptionV0ValueEnum;
}


/**
 * @export
 */
export const AccessPolicyArchiveSearchOptionV0IdEnum = {
    ArchiveSearch: 'archive_search'
} as const;
export type AccessPolicyArchiveSearchOptionV0IdEnum = typeof AccessPolicyArchiveSearchOptionV0IdEnum[keyof typeof AccessPolicyArchiveSearchOptionV0IdEnum];

/**
 * @export
 */
export const AccessPolicyArchiveSearchOptionV0ValueEnum = {
    Read: 'read',
    Readdownload: 'read+download'
} as const;
export type AccessPolicyArchiveSearchOptionV0ValueEnum = typeof AccessPolicyArchiveSearchOptionV0ValueEnum[keyof typeof AccessPolicyArchiveSearchOptionV0ValueEnum];


/**
 * Check if a given object implements the AccessPolicyArchiveSearchOptionV0 interface.
 */
export function instanceOfAccessPolicyArchiveSearchOptionV0(value: object): value is AccessPolicyArchiveSearchOptionV0 {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('value' in value) || value['value'] === undefined) return false;
    return true;
}

export function AccessPolicyArchiveSearchOptionV0FromJSON(json: any): AccessPolicyArchiveSearchOptionV0 {
    return AccessPolicyArchiveSearchOptionV0FromJSONTyped(json, false);
}

export function AccessPolicyArchiveSearchOptionV0FromJSONTyped(json: any, ignoreDiscriminator: boolean): AccessPolicyArchiveSearchOptionV0 {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'value': json['value'],
    };
}

export function AccessPolicyArchiveSearchOptionV0ToJSON(value?: AccessPolicyArchiveSearchOptionV0 | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'value': value['value'],
    };
}

