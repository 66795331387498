/* tslint:disable */
/* eslint-disable */
/**
 * HENNGE Email Archive API
 * HENNGE Email Archive administration and search API.
 *
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AttachmentSearchPolicyRuleV0
 */
export interface AttachmentSearchPolicyRuleV0 {
    /**
     * 
     * @type {string}
     * @memberof AttachmentSearchPolicyRuleV0
     */
    field: AttachmentSearchPolicyRuleV0FieldEnum;
    /**
     * 
     * @type {string}
     * @memberof AttachmentSearchPolicyRuleV0
     */
    type: AttachmentSearchPolicyRuleV0TypeEnum;
}


/**
 * @export
 */
export const AttachmentSearchPolicyRuleV0FieldEnum = {
    HasAttachment: 'has_attachment'
} as const;
export type AttachmentSearchPolicyRuleV0FieldEnum = typeof AttachmentSearchPolicyRuleV0FieldEnum[keyof typeof AttachmentSearchPolicyRuleV0FieldEnum];

/**
 * @export
 */
export const AttachmentSearchPolicyRuleV0TypeEnum = {
    Exist: 'exist'
} as const;
export type AttachmentSearchPolicyRuleV0TypeEnum = typeof AttachmentSearchPolicyRuleV0TypeEnum[keyof typeof AttachmentSearchPolicyRuleV0TypeEnum];


/**
 * Check if a given object implements the AttachmentSearchPolicyRuleV0 interface.
 */
export function instanceOfAttachmentSearchPolicyRuleV0(value: object): value is AttachmentSearchPolicyRuleV0 {
    if (!('field' in value) || value['field'] === undefined) return false;
    if (!('type' in value) || value['type'] === undefined) return false;
    return true;
}

export function AttachmentSearchPolicyRuleV0FromJSON(json: any): AttachmentSearchPolicyRuleV0 {
    return AttachmentSearchPolicyRuleV0FromJSONTyped(json, false);
}

export function AttachmentSearchPolicyRuleV0FromJSONTyped(json: any, ignoreDiscriminator: boolean): AttachmentSearchPolicyRuleV0 {
    if (json == null) {
        return json;
    }
    return {
        
        'field': json['field'],
        'type': json['type'],
    };
}

export function AttachmentSearchPolicyRuleV0ToJSON(value?: AttachmentSearchPolicyRuleV0 | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'field': value['field'],
        'type': value['type'],
    };
}

