/* tslint:disable */
/* eslint-disable */
/**
 * HENNGE Email Archive API
 * HENNGE Email Archive administration and search API.
 *
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AccessPolicyConsoleSettingsOptionV0
 */
export interface AccessPolicyConsoleSettingsOptionV0 {
    /**
     * 
     * @type {string}
     * @memberof AccessPolicyConsoleSettingsOptionV0
     */
    id: AccessPolicyConsoleSettingsOptionV0IdEnum;
    /**
     * 
     * @type {string}
     * @memberof AccessPolicyConsoleSettingsOptionV0
     */
    value: AccessPolicyConsoleSettingsOptionV0ValueEnum;
}


/**
 * @export
 */
export const AccessPolicyConsoleSettingsOptionV0IdEnum = {
    ConsoleSettings: 'console_settings'
} as const;
export type AccessPolicyConsoleSettingsOptionV0IdEnum = typeof AccessPolicyConsoleSettingsOptionV0IdEnum[keyof typeof AccessPolicyConsoleSettingsOptionV0IdEnum];

/**
 * @export
 */
export const AccessPolicyConsoleSettingsOptionV0ValueEnum = {
    Read: 'read',
    Readwrite: 'read+write'
} as const;
export type AccessPolicyConsoleSettingsOptionV0ValueEnum = typeof AccessPolicyConsoleSettingsOptionV0ValueEnum[keyof typeof AccessPolicyConsoleSettingsOptionV0ValueEnum];


/**
 * Check if a given object implements the AccessPolicyConsoleSettingsOptionV0 interface.
 */
export function instanceOfAccessPolicyConsoleSettingsOptionV0(value: object): value is AccessPolicyConsoleSettingsOptionV0 {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('value' in value) || value['value'] === undefined) return false;
    return true;
}

export function AccessPolicyConsoleSettingsOptionV0FromJSON(json: any): AccessPolicyConsoleSettingsOptionV0 {
    return AccessPolicyConsoleSettingsOptionV0FromJSONTyped(json, false);
}

export function AccessPolicyConsoleSettingsOptionV0FromJSONTyped(json: any, ignoreDiscriminator: boolean): AccessPolicyConsoleSettingsOptionV0 {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'value': json['value'],
    };
}

export function AccessPolicyConsoleSettingsOptionV0ToJSON(value?: AccessPolicyConsoleSettingsOptionV0 | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'value': value['value'],
    };
}

