<template>
  <span class="BetaTag">BETA</span>
</template>

<style scoped lang="scss">
.BetaTag {
  display: block;
  background-color: white();
  color: primary(700);
  font-weight: bold;
  font-size: 12px;
  line-height: 1;
  margin-left: 8px;
  padding: 4px 10px;
  border-radius: 72px;
}
</style>
