<template>
  <header class="GlobalHeader">
    <menu class="Holder" role="menubar" :aria-label="$t('globalHeader.mainMenu')">
      <li role="none" class="GlobalActionHolder">
        <button
          v-if="tenant.isLoggedIn"
          role="menuitem"
          :title="$t('arias.returnToMainPage')"
          :aria-label="$t('arias.returnToMainPage')"
          type="button"
          data-testid="MainLogoButton"
          class="GlobalAction"
          tabindex="0"
          @click="() => router.push('/')"
        >
          <HeaderTitle />
        </button>
        <div v-else role="menuitem" aria-disabled="true" class="HeaderTitleContainer">
          <HeaderTitle />
        </div>
      </li>

      <li class="Expander" role="separator" aria-orientation="vertical" />
      <HeaderSettings />
    </menu>
  </header>
</template>

<script setup lang="ts">
const router = useRouter();
const { tenant } = useStores('tenant');
</script>

<style scoped lang="scss">
.GlobalHeader {
  display: flex;
  background-color: neutral(800);
  height: $global-header-height;
  position: fixed;
  z-index: 700;
  width: 100%;
}

.Holder {
  position: relative;
  display: flex;
  align-items: center;
  padding: 0 8px;
  margin: 0;
  width: 100%;
  gap: 8px;
}

.Expander {
  flex: 1 0 auto;
  list-style: none;
}

.HeaderTitleContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
}
</style>
