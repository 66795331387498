/* tslint:disable */
/* eslint-disable */
/**
 * HENNGE Email Archive API
 * HENNGE Email Archive administration and search API.
 *
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { NamedAccessPolicyWithOptionsV0 } from './NamedAccessPolicyWithOptionsV0';
import {
    NamedAccessPolicyWithOptionsV0FromJSON,
    NamedAccessPolicyWithOptionsV0FromJSONTyped,
    NamedAccessPolicyWithOptionsV0ToJSON,
} from './NamedAccessPolicyWithOptionsV0';

/**
 * List of named access policies with options.
 * @export
 * @interface AccessPoliciesV0
 */
export interface AccessPoliciesV0 {
    /**
     * 
     * @type {Array<NamedAccessPolicyWithOptionsV0>}
     * @memberof AccessPoliciesV0
     */
    namedPolicies: Array<NamedAccessPolicyWithOptionsV0>;
}

/**
 * Check if a given object implements the AccessPoliciesV0 interface.
 */
export function instanceOfAccessPoliciesV0(value: object): value is AccessPoliciesV0 {
    if (!('namedPolicies' in value) || value['namedPolicies'] === undefined) return false;
    return true;
}

export function AccessPoliciesV0FromJSON(json: any): AccessPoliciesV0 {
    return AccessPoliciesV0FromJSONTyped(json, false);
}

export function AccessPoliciesV0FromJSONTyped(json: any, ignoreDiscriminator: boolean): AccessPoliciesV0 {
    if (json == null) {
        return json;
    }
    return {
        
        'namedPolicies': ((json['named_policies'] as Array<any>).map(NamedAccessPolicyWithOptionsV0FromJSON)),
    };
}

export function AccessPoliciesV0ToJSON(value?: AccessPoliciesV0 | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'named_policies': ((value['namedPolicies'] as Array<any>).map(NamedAccessPolicyWithOptionsV0ToJSON)),
    };
}

