/* tslint:disable */
/* eslint-disable */
/**
 * HENNGE Email Archive API
 * HENNGE Email Archive administration and search API.
 *
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ArchiveStatusV0 } from './ArchiveStatusV0';
import {
    ArchiveStatusV0FromJSON,
    ArchiveStatusV0FromJSONTyped,
    ArchiveStatusV0ToJSON,
} from './ArchiveStatusV0';

/**
 * 
 * @export
 * @interface StorageStatusSearchConditionV0
 */
export interface StorageStatusSearchConditionV0 {
    /**
     * 
     * @type {string}
     * @memberof StorageStatusSearchConditionV0
     */
    field: StorageStatusSearchConditionV0FieldEnum;
    /**
     * 
     * @type {Array<ArchiveStatusV0>}
     * @memberof StorageStatusSearchConditionV0
     */
    values: Array<ArchiveStatusV0>;
}


/**
 * @export
 */
export const StorageStatusSearchConditionV0FieldEnum = {
    StorageStatus: 'storage_status'
} as const;
export type StorageStatusSearchConditionV0FieldEnum = typeof StorageStatusSearchConditionV0FieldEnum[keyof typeof StorageStatusSearchConditionV0FieldEnum];


/**
 * Check if a given object implements the StorageStatusSearchConditionV0 interface.
 */
export function instanceOfStorageStatusSearchConditionV0(value: object): value is StorageStatusSearchConditionV0 {
    if (!('field' in value) || value['field'] === undefined) return false;
    if (!('values' in value) || value['values'] === undefined) return false;
    return true;
}

export function StorageStatusSearchConditionV0FromJSON(json: any): StorageStatusSearchConditionV0 {
    return StorageStatusSearchConditionV0FromJSONTyped(json, false);
}

export function StorageStatusSearchConditionV0FromJSONTyped(json: any, ignoreDiscriminator: boolean): StorageStatusSearchConditionV0 {
    if (json == null) {
        return json;
    }
    return {
        
        'field': json['field'],
        'values': ((json['values'] as Array<any>).map(ArchiveStatusV0FromJSON)),
    };
}

export function StorageStatusSearchConditionV0ToJSON(value?: StorageStatusSearchConditionV0 | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'field': value['field'],
        'values': ((value['values'] as Array<any>).map(ArchiveStatusV0ToJSON)),
    };
}

