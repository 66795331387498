/* tslint:disable */
/* eslint-disable */
/**
 * HENNGE Email Archive API
 * HENNGE Email Archive administration and search API.
 *
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AccessPolicyUserSettingsOptionV0
 */
export interface AccessPolicyUserSettingsOptionV0 {
    /**
     * 
     * @type {string}
     * @memberof AccessPolicyUserSettingsOptionV0
     */
    id: AccessPolicyUserSettingsOptionV0IdEnum;
    /**
     * 
     * @type {string}
     * @memberof AccessPolicyUserSettingsOptionV0
     */
    value: AccessPolicyUserSettingsOptionV0ValueEnum;
}


/**
 * @export
 */
export const AccessPolicyUserSettingsOptionV0IdEnum = {
    UserSettings: 'user_settings'
} as const;
export type AccessPolicyUserSettingsOptionV0IdEnum = typeof AccessPolicyUserSettingsOptionV0IdEnum[keyof typeof AccessPolicyUserSettingsOptionV0IdEnum];

/**
 * @export
 */
export const AccessPolicyUserSettingsOptionV0ValueEnum = {
    Read: 'read',
    Readwrite: 'read+write'
} as const;
export type AccessPolicyUserSettingsOptionV0ValueEnum = typeof AccessPolicyUserSettingsOptionV0ValueEnum[keyof typeof AccessPolicyUserSettingsOptionV0ValueEnum];


/**
 * Check if a given object implements the AccessPolicyUserSettingsOptionV0 interface.
 */
export function instanceOfAccessPolicyUserSettingsOptionV0(value: object): value is AccessPolicyUserSettingsOptionV0 {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('value' in value) || value['value'] === undefined) return false;
    return true;
}

export function AccessPolicyUserSettingsOptionV0FromJSON(json: any): AccessPolicyUserSettingsOptionV0 {
    return AccessPolicyUserSettingsOptionV0FromJSONTyped(json, false);
}

export function AccessPolicyUserSettingsOptionV0FromJSONTyped(json: any, ignoreDiscriminator: boolean): AccessPolicyUserSettingsOptionV0 {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'value': json['value'],
    };
}

export function AccessPolicyUserSettingsOptionV0ToJSON(value?: AccessPolicyUserSettingsOptionV0 | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'value': value['value'],
    };
}

