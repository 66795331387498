/* prettier-ignore */
export const useApiEndpointsStore = defineStore('apiEndpoints', () => ({
  // Access Policies API
  getAccessPolicies:               readonly(ref(useWrappedApi('accessPoliciesApi', 'getAccessPolicies'))),

  // Archive API
  defrostMail:             readonly(ref(useWrappedApi('archiveApi', 'defrostMail'))),
  downloadMail:            readonly(ref(useWrappedApi('archiveApi', 'downloadMail'))),
  downloadMailAttachment:  readonly(ref(useWrappedApi('archiveApi', 'downloadMailAttachment'))),
  previewMailAttachment:   readonly(ref(useWrappedApi('archiveApi', 'previewMailAttachment'))),
  getArchiveSettings:      readonly(ref(useWrappedApi('archiveApi', 'getArchiveSettings'))),
  getMailDetails:          readonly(ref(useWrappedApi('archiveApi', 'getMailDetails'))),
  getMailHeaders:          readonly(ref(useWrappedApi('archiveApi', 'getMailHeaders'))),
  searchArchive:           readonly(ref(useWrappedApi('archiveApi', 'searchArchive'))),
  getSearchHistory:        readonly(ref(useWrappedApi('archiveApi', 'getSearchHistory'))),

  // Auth API
  getAccessToken:                  readonly(ref(useWrappedApi('authApi', 'getAccessToken'))),
  getJwks:                         readonly(ref(useWrappedApi('authApi', 'getJwks'))),
  getLoginUrl:                     readonly(ref(useWrappedApi('authApi', 'getLoginUrl'))),
  getUserInfo:                     readonly(ref(useWrappedApi('authApi', 'getUserInfo'))),
  logout:                          readonly(ref(useWrappedApi('authApi', 'logout'))),
  updateUserInfo:                  readonly(ref(useWrappedApi('authApi', 'updateUserInfo'))),

  // Discovery API
  getDiscoveryDocWellKnownOpenidConfigurationGet: readonly(ref(useWrappedApi('discoveryApi', 'getDiscoveryDocWellKnownOpenidConfigurationGet'))),

  // Domain API
  getDomain:                       readonly(ref(useWrappedApi('domainApi', 'getDomain'))),
  updateDomain:                    readonly(ref(useWrappedApi('domainApi', 'updateDomain'))),

  // Operation Logs API
  downloadOperationLogs:           readonly(ref(useWrappedApi('operationLogsApi', 'downloadOperationLogs'))),
  getOperationLogs:                readonly(ref(useWrappedApi('operationLogsApi', 'getOperationLogs'))),
  downloadFilteredOperationLogs:   readonly(ref(useWrappedApi('operationLogsApi', 'downloadFilteredOperationLogs'))),

  // Search Policies API
  createSearchPolicy:              readonly(ref(useWrappedApi('searchPoliciesApi', 'createSearchPolicy'))),
  deleteSearchPolicy:              readonly(ref(useWrappedApi('searchPoliciesApi', 'deleteSearchPolicy'))),
  getSearchPolicies:               readonly(ref(useWrappedApi('searchPoliciesApi', 'getSearchPolicies'))),
  getSearchPolicy:                 readonly(ref(useWrappedApi('searchPoliciesApi', 'getSearchPolicy'))),
  getSearchPolicyUsers:            readonly(ref(useWrappedApi('searchPoliciesApi', 'getSearchPolicyUsers'))),
  updateSearchPolicy:              readonly(ref(useWrappedApi('searchPoliciesApi', 'updateSearchPolicy'))),

  // Stats API
  getRecentArchiveStatistics:      readonly(ref(useWrappedApi('statsApi', 'getRecentArchiveStatistics'))),

  // Users API
  createUser:                      readonly(ref(useWrappedApi('usersApi', 'createUser'))),
  deleteUser:                      readonly(ref(useWrappedApi('usersApi', 'deleteUser'))),
  updateUser:                      readonly(ref(useWrappedApi('usersApi', 'updateUser'))),
  getUser:                         readonly(ref(useWrappedApi('usersApi', 'getUser'))),
  getUsers:                        readonly(ref(useWrappedApi('usersApi', 'getUsers'))),
}));

export default useApiEndpointsStore;

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useApiEndpointsStore, import.meta.hot));
}
