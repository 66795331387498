/* tslint:disable */
/* eslint-disable */
/**
 * HENNGE Email Archive API
 * HENNGE Email Archive administration and search API.
 *
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PreservationPeriodWithBackupPeriodGte10YearsV0
 */
export interface PreservationPeriodWithBackupPeriodGte10YearsV0 {
    /**
     * A frozen period of ten or more years.
     * @type {string}
     * @memberof PreservationPeriodWithBackupPeriodGte10YearsV0
     */
    frozenPeriod: PreservationPeriodWithBackupPeriodGte10YearsV0FrozenPeriodEnum;
    /**
     * 
     * @type {number}
     * @memberof PreservationPeriodWithBackupPeriodGte10YearsV0
     */
    realtimePeriodMonths: number;
}


/**
 * @export
 */
export const PreservationPeriodWithBackupPeriodGte10YearsV0FrozenPeriodEnum = {
    Gte10years: 'gte_10years'
} as const;
export type PreservationPeriodWithBackupPeriodGte10YearsV0FrozenPeriodEnum = typeof PreservationPeriodWithBackupPeriodGte10YearsV0FrozenPeriodEnum[keyof typeof PreservationPeriodWithBackupPeriodGte10YearsV0FrozenPeriodEnum];


/**
 * Check if a given object implements the PreservationPeriodWithBackupPeriodGte10YearsV0 interface.
 */
export function instanceOfPreservationPeriodWithBackupPeriodGte10YearsV0(value: object): value is PreservationPeriodWithBackupPeriodGte10YearsV0 {
    if (!('frozenPeriod' in value) || value['frozenPeriod'] === undefined) return false;
    if (!('realtimePeriodMonths' in value) || value['realtimePeriodMonths'] === undefined) return false;
    return true;
}

export function PreservationPeriodWithBackupPeriodGte10YearsV0FromJSON(json: any): PreservationPeriodWithBackupPeriodGte10YearsV0 {
    return PreservationPeriodWithBackupPeriodGte10YearsV0FromJSONTyped(json, false);
}

export function PreservationPeriodWithBackupPeriodGte10YearsV0FromJSONTyped(json: any, ignoreDiscriminator: boolean): PreservationPeriodWithBackupPeriodGte10YearsV0 {
    if (json == null) {
        return json;
    }
    return {
        
        'frozenPeriod': json['frozen_period'],
        'realtimePeriodMonths': json['realtime_period_months'],
    };
}

export function PreservationPeriodWithBackupPeriodGte10YearsV0ToJSON(value?: PreservationPeriodWithBackupPeriodGte10YearsV0 | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'frozen_period': value['frozenPeriod'],
        'realtime_period_months': value['realtimePeriodMonths'],
    };
}

