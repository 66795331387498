/* tslint:disable */
/* eslint-disable */
/**
 * HENNGE Email Archive API
 * HENNGE Email Archive administration and search API.
 *
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DiscoveryDocument
 */
export interface DiscoveryDocument {
    /**
     * 
     * @type {string}
     * @memberof DiscoveryDocument
     */
    authorizationEndpoint: string;
    /**
     * 
     * @type {string}
     * @memberof DiscoveryDocument
     */
    issuer: string;
    /**
     * 
     * @type {string}
     * @memberof DiscoveryDocument
     */
    jwksUri: string;
    /**
     * 
     * @type {string}
     * @memberof DiscoveryDocument
     */
    tokenEndpoint: string;
}

/**
 * Check if a given object implements the DiscoveryDocument interface.
 */
export function instanceOfDiscoveryDocument(value: object): value is DiscoveryDocument {
    if (!('authorizationEndpoint' in value) || value['authorizationEndpoint'] === undefined) return false;
    if (!('issuer' in value) || value['issuer'] === undefined) return false;
    if (!('jwksUri' in value) || value['jwksUri'] === undefined) return false;
    if (!('tokenEndpoint' in value) || value['tokenEndpoint'] === undefined) return false;
    return true;
}

export function DiscoveryDocumentFromJSON(json: any): DiscoveryDocument {
    return DiscoveryDocumentFromJSONTyped(json, false);
}

export function DiscoveryDocumentFromJSONTyped(json: any, ignoreDiscriminator: boolean): DiscoveryDocument {
    if (json == null) {
        return json;
    }
    return {
        
        'authorizationEndpoint': json['authorization_endpoint'],
        'issuer': json['issuer'],
        'jwksUri': json['jwks_uri'],
        'tokenEndpoint': json['token_endpoint'],
    };
}

export function DiscoveryDocumentToJSON(value?: DiscoveryDocument | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'authorization_endpoint': value['authorizationEndpoint'],
        'issuer': value['issuer'],
        'jwks_uri': value['jwksUri'],
        'token_endpoint': value['tokenEndpoint'],
    };
}

