/**
 *  I18N PLUGIN
 */

import { useI18n as _useI18n, createI18n } from 'vue-i18n';

import EnglishTranslations from './en.json';
import JapaneseTranslations from './ja.json';

/**
 *  TYPES
 */

// Awesome; from https://www.raygesualdo.com/posts/flattening-object-keys-with-typescript-types
// This converts nested keys into a flattened dot-notation
type FlattenObjectKeys<T extends Record<string, unknown>, Key = keyof T> = Key extends string
  ? T[Key] extends Record<string, unknown>
    ? `${Key}.${FlattenObjectKeys<T[Key]>}`
    : `${Key}`
  : never;

/**
 * The below type checks confirm that there are no top-level locale keys;
 * I.e. all locale keys must be contained within at least 1 category within the JSON files.
 * If there is an error with the below type checks, it means that an uncategorized locale key was introduced.
 */
EnglishTranslations satisfies Record<string, Record<string, unknown>>;
JapaneseTranslations satisfies Record<string, Record<string, unknown>>;

type EnglishTranslationKey = FlattenObjectKeys<typeof EnglishTranslations>;
type JapaneseTranslationKey = FlattenObjectKeys<typeof JapaneseTranslations>;
export type TranslationKey = EnglishTranslationKey & JapaneseTranslationKey;

export type JoinedSchema = typeof EnglishTranslations & typeof JapaneseTranslations;

// If there are TS errors with the next two lines, it means that
// there are inconsistencies between the "en" and "ja" schemae
// i.e. some value doesn't exist in the other
const en: JoinedSchema = EnglishTranslations;
const ja: JoinedSchema = JapaneseTranslations;

export const NobitaLocales = ['en-US', 'ja-JP'] as const;
export type NobitaLocale = (typeof NobitaLocales)[number];

export const NobitaShortLocaleMappings = {
  en: 'en-US',
  ja: 'ja-JP',
} as const;

export function isValidLocale(value: string | null): value is NobitaLocale {
  if (value === null) return false;
  return (NobitaLocales as readonly string[]).includes(value);
}

export function normalizeLocale(value: string | null): NobitaLocale | null {
  if (value === null) return null;
  if (isValidLocale(value)) return value;

  const shortLang = value.split('-')[0];

  if (shortLang in NobitaShortLocaleMappings) {
    return NobitaShortLocaleMappings[shortLang as keyof typeof NobitaShortLocaleMappings];
  }

  return null;
}

export const i18n = createI18n<[JoinedSchema], NobitaLocale, false>({
  legacy: false,
  locale: 'ja-JP',
  fallbackLocale: 'en-US',
  messages: {
    'ja-JP': ja,
    'en-US': en,
  },
});

// Export our own version of useI18n with narrowed locale types
export const useI18n = _useI18n<[JoinedSchema], NobitaLocale>;

// Hot module replacement
if (import.meta.hot) {
  import.meta.hot.accept(['./en.json', './ja.json'], ([newEN, newJA]) => {
    if (newEN) {
      i18n.global.setLocaleMessage<JoinedSchema>('en-US', newEN.default as JoinedSchema);
    }
    if (newJA) {
      i18n.global.setLocaleMessage<JoinedSchema>('ja-JP', newJA.default as JoinedSchema);
    }
  });
}

export default i18n;

export * from './v-calendar';
