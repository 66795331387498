/* tslint:disable */
/* eslint-disable */
/**
 * HENNGE Email Archive API
 * HENNGE Email Archive administration and search API.
 *
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface OperationLogDownloadQueryV0
 */
export interface OperationLogDownloadQueryV0 {
    /**
     * 
     * @type {Date}
     * @memberof OperationLogDownloadQueryV0
     */
    end: Date;
    /**
     * 
     * @type {Date}
     * @memberof OperationLogDownloadQueryV0
     */
    start: Date;
}

/**
 * Check if a given object implements the OperationLogDownloadQueryV0 interface.
 */
export function instanceOfOperationLogDownloadQueryV0(value: object): value is OperationLogDownloadQueryV0 {
    if (!('end' in value) || value['end'] === undefined) return false;
    if (!('start' in value) || value['start'] === undefined) return false;
    return true;
}

export function OperationLogDownloadQueryV0FromJSON(json: any): OperationLogDownloadQueryV0 {
    return OperationLogDownloadQueryV0FromJSONTyped(json, false);
}

export function OperationLogDownloadQueryV0FromJSONTyped(json: any, ignoreDiscriminator: boolean): OperationLogDownloadQueryV0 {
    if (json == null) {
        return json;
    }
    return {
        
        'end': (new Date(json['end'])),
        'start': (new Date(json['start'])),
    };
}

export function OperationLogDownloadQueryV0ToJSON(value?: OperationLogDownloadQueryV0 | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'end': ((value['end']).toISOString()),
        'start': ((value['start']).toISOString()),
    };
}

