/**
 * Auth helper
 *
 * @desc helps do CRUD in components for authentication endpoints
 */

import type {
  ApiResponse,
  FetchParams,
  Middleware,
  ProviderRequestV0,
  RequestContext,
  TokenResponseV0,
  URLResponseV0,
  UserInfoUpdateV0,
  UserInfoV0,
} from '@/api';

import { AuthApi, Configuration } from '@/api';
import { NobitaError } from '@/errors';

/**
 * Used to add the preview_path query parameter to the login URL for preview builds
 */
class PreviewMiddleware implements Middleware {
  pre({ url, init }: RequestContext): Promise<FetchParams | void> {
    if (url.match(/\/auth\/login_url$/)) {
      return Promise.resolve({
        url: url + `?preview_path=${import.meta.env.BASE_URL}`,
        init,
      });
    } else {
      return Promise.resolve({ url, init });
    }
  }
}

export type GetAccessTokenResponse = TokenResponseV0 | NobitaError | false;

export function useAuthHelper() {
  const { stored_accessToken, stored_loginHint, stored_loginErrorCode, stored_loginErrorContext } =
    useNobitaLocalStorage();

  const IS_PREVIEW_BUILD =
    import.meta.env.VITE_PREVIEW_MIDDLEWARE_ENABLED === 'true' &&
    import.meta.env.BASE_URL.startsWith('/pr-');

  const loginApi = computed(
    () =>
      new AuthApi(
        new Configuration({
          ...buildBaseApiConfigParameters(null),
          middleware: IS_PREVIEW_BUILD ? [new PreviewMiddleware()] : undefined,
        }),
      ),
  );

  async function getAccessToken(url: string): Promise<GetAccessTokenResponse> {
    const { tenant } = useStores('tenant');
    logger.info('Requesting token for URL:', url);

    try {
      const response: TokenResponseV0 = await loginApi.value.getAccessToken({
        tokenRequestV0: { url },
      });

      logger.debug('Access token', { token: response.accessToken });

      // set token in local storage (which is read by tenant store)
      stored_accessToken.value = response.accessToken;
      stored_loginHint.value = response.loginHint ?? null;

      return response;
    } catch (e: unknown) {
      const err = await handleError(e, 'getAccessToken');

      // clear values in local storage
      stored_accessToken.value = null;
      stored_loginHint.value = null;
      tenant.setLoggedInStatus(false);

      if (err instanceof NobitaError) {
        stored_loginErrorCode.value = err.nobitaErrorCode.toString();
        stored_loginErrorContext.value =
          err.context && 'onboarding_request' in err.context && err.context.onboarding_request
            ? 'onboarding_request'
            : '';

        return err;
      }

      stored_loginErrorCode.value = 'unknown-failure';
      logger.error('Failed to get access token for an unknown reason', err);
      return false;
    }
  }

  async function getUserInfo(accessToken: string | null): Promise<UserInfoV0> {
    try {
      if (accessToken === '' || accessToken === null) throw new Error('No access token found');

      logger.debug('Requesting user info for access token:', accessToken);

      const response: ApiResponse<UserInfoV0> = await loginApi.value.getUserInfoRaw(
        accessToken
          ? {
              headers: { Authorization: `Bearer ${accessToken}` },
            }
          : undefined,
      );

      if (response.raw.status === 403) throw new Error('Session timed out');
      if (!response.raw.ok) throw new Error(response.raw.statusText);

      const result = await response.value();

      logger.debug('Get User Info results', result);
      return result;
    } catch (e: unknown) {
      logger.error('Failed to get user info:', e);
      throw e;
    }
  }

  async function updateUserInfo(userInfoUpdate: UserInfoUpdateV0): Promise<UserInfoV0> {
    try {
      logger.info('Updating user info');

      const response: ApiResponse<UserInfoV0> = await loginApi.value.updateUserInfoRaw(
        { userInfoUpdateV0: userInfoUpdate },
        {
          headers: {
            Authorization: `Bearer ${stored_accessToken.value}`,
            'Content-Type': 'application/json',
          },
        },
      );

      if (!response.raw.ok) throw new Error(response.raw.statusText);

      const result = await response.value();

      logger.debug('User Info Update result:', result);

      return result;
    } catch (e: unknown) {
      logger.error('Failed to update user info:', e);
      throw e;
    }
  }

  async function getLoginUrl(providerRequest: ProviderRequestV0): Promise<string> {
    try {
      logger.debug('Login URL Request:', providerRequest.domainName);

      const response: ApiResponse<URLResponseV0> = await loginApi.value.getLoginUrlRaw({
        providerRequestV0: providerRequest,
      });

      if (!response.raw.ok) throw new Error(response.raw.statusText);

      const result = await response.value();

      logger.debug(result);

      return result.url;
    } catch (e) {
      logger.error('Failed to get login URL:', e);
      throw e;
    }
  }

  async function logout(accessToken: string): Promise<void> {
    try {
      logger.info('Logging out...');

      const response: ApiResponse<void> = await loginApi.value.logoutRaw(
        accessToken
          ? {
              headers: { Authorization: `Bearer ${accessToken}` },
            }
          : undefined,
      );

      if (!response.raw.ok) throw new Error(response.raw.statusText);

      logger.info('Previous session revoked');

      return;
    } catch (e) {
      logger.error('Could not logout with access token:', e);
      throw e;
    }
  }

  return {
    getAccessToken,
    getUserInfo,
    updateUserInfo,
    getLoginUrl,
    logout,
  };
}
