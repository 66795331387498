import type { RemovableRef, UseStorageOptions } from '@vueuse/core';

import { StorageSerializers } from '@vueuse/core';

const optionsStringNoDefault: UseStorageOptions<string> = {
  writeDefaults: false,
  shallow: true,
  serializer: StorageSerializers.string,
};

// This fixes wrong typing in vueuse
// https://github.com/vueuse/vueuse/issues/3778
function nullable<T>(ref: RemovableRef<T>): RemovableRef<T | null> {
  return ref;
}

export function useNobitaLocalStorage() {
  // prettier-ignore
  return {
    // NOTE: We should wrap all `useLocalStorage` in `nullable` for more robust typing at caller side
    stored_accessToken:               nullable(useLocalStorage('access-token', '', optionsStringNoDefault)),
    stored_autoReLogin:               nullable(useLocalStorage('auto-relogin', false)),
    stored_domainName:                nullable(useLocalStorage('domain-name', '', optionsStringNoDefault)),
    stored_currentRoute:              nullable(useLocalStorage('current-route', '', optionsStringNoDefault)),
    stored_loginHint:                 nullable(useLocalStorage('login-hint', '', optionsStringNoDefault)),
    stored_loginErrorCode:            nullable(useLocalStorage('login-error-code', '')),
    stored_loginErrorContext:         nullable(useLocalStorage('login-error-context', '')),
    stored_loginLocale:               nullable(useLocalStorage('login-locale', '', optionsStringNoDefault)),
    stored_locale:                    nullable(useLocalStorage('locale', '', optionsStringNoDefault)),
    stored_advancedSelectorSections:  nullable(useLocalStorage(
      'advanced-selector-sections',
      new Set<string>([]),
    )),
  };
}
