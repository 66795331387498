/* tslint:disable */
/* eslint-disable */
/**
 * HENNGE Email Archive API
 * HENNGE Email Archive administration and search API.
 *
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { JWKV0 } from './JWKV0';
import {
    JWKV0FromJSON,
    JWKV0FromJSONTyped,
    JWKV0ToJSON,
} from './JWKV0';

/**
 * 
 * @export
 * @interface JWKSResponseV0
 */
export interface JWKSResponseV0 {
    /**
     * 
     * @type {Array<JWKV0>}
     * @memberof JWKSResponseV0
     */
    keys: Array<JWKV0>;
}

/**
 * Check if a given object implements the JWKSResponseV0 interface.
 */
export function instanceOfJWKSResponseV0(value: object): value is JWKSResponseV0 {
    if (!('keys' in value) || value['keys'] === undefined) return false;
    return true;
}

export function JWKSResponseV0FromJSON(json: any): JWKSResponseV0 {
    return JWKSResponseV0FromJSONTyped(json, false);
}

export function JWKSResponseV0FromJSONTyped(json: any, ignoreDiscriminator: boolean): JWKSResponseV0 {
    if (json == null) {
        return json;
    }
    return {
        
        'keys': ((json['keys'] as Array<any>).map(JWKV0FromJSON)),
    };
}

export function JWKSResponseV0ToJSON(value?: JWKSResponseV0 | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'keys': ((value['keys'] as Array<any>).map(JWKV0ToJSON)),
    };
}

