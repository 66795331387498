/* tslint:disable */
/* eslint-disable */
/**
 * HENNGE Email Archive API
 * HENNGE Email Archive administration and search API.
 *
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SubjectBodySearchConditionV0
 */
export interface SubjectBodySearchConditionV0 {
    /**
     * 
     * @type {string}
     * @memberof SubjectBodySearchConditionV0
     */
    field: SubjectBodySearchConditionV0FieldEnum;
    /**
     * 
     * @type {string}
     * @memberof SubjectBodySearchConditionV0
     */
    type: SubjectBodySearchConditionV0TypeEnum;
    /**
     * 
     * @type {Array<string>}
     * @memberof SubjectBodySearchConditionV0
     */
    values: Array<string>;
}


/**
 * @export
 */
export const SubjectBodySearchConditionV0FieldEnum = {
    SubjectBody: 'subject_body'
} as const;
export type SubjectBodySearchConditionV0FieldEnum = typeof SubjectBodySearchConditionV0FieldEnum[keyof typeof SubjectBodySearchConditionV0FieldEnum];

/**
 * @export
 */
export const SubjectBodySearchConditionV0TypeEnum = {
    Contain: 'contain',
    NotContain: 'not_contain'
} as const;
export type SubjectBodySearchConditionV0TypeEnum = typeof SubjectBodySearchConditionV0TypeEnum[keyof typeof SubjectBodySearchConditionV0TypeEnum];


/**
 * Check if a given object implements the SubjectBodySearchConditionV0 interface.
 */
export function instanceOfSubjectBodySearchConditionV0(value: object): value is SubjectBodySearchConditionV0 {
    if (!('field' in value) || value['field'] === undefined) return false;
    if (!('type' in value) || value['type'] === undefined) return false;
    if (!('values' in value) || value['values'] === undefined) return false;
    return true;
}

export function SubjectBodySearchConditionV0FromJSON(json: any): SubjectBodySearchConditionV0 {
    return SubjectBodySearchConditionV0FromJSONTyped(json, false);
}

export function SubjectBodySearchConditionV0FromJSONTyped(json: any, ignoreDiscriminator: boolean): SubjectBodySearchConditionV0 {
    if (json == null) {
        return json;
    }
    return {
        
        'field': json['field'],
        'type': json['type'],
        'values': json['values'],
    };
}

export function SubjectBodySearchConditionV0ToJSON(value?: SubjectBodySearchConditionV0 | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'field': value['field'],
        'type': value['type'],
        'values': value['values'],
    };
}

