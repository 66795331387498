/* tslint:disable */
/* eslint-disable */
/**
 * HENNGE Email Archive API
 * HENNGE Email Archive administration and search API.
 *
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface EnvelopeToSearchConditionV0
 */
export interface EnvelopeToSearchConditionV0 {
    /**
     * 
     * @type {string}
     * @memberof EnvelopeToSearchConditionV0
     */
    field: EnvelopeToSearchConditionV0FieldEnum;
    /**
     * 
     * @type {string}
     * @memberof EnvelopeToSearchConditionV0
     */
    type: EnvelopeToSearchConditionV0TypeEnum;
    /**
     * 
     * @type {Array<string>}
     * @memberof EnvelopeToSearchConditionV0
     */
    values: Array<string>;
}


/**
 * @export
 */
export const EnvelopeToSearchConditionV0FieldEnum = {
    EnvelopeTo: 'envelope_to'
} as const;
export type EnvelopeToSearchConditionV0FieldEnum = typeof EnvelopeToSearchConditionV0FieldEnum[keyof typeof EnvelopeToSearchConditionV0FieldEnum];

/**
 * @export
 */
export const EnvelopeToSearchConditionV0TypeEnum = {
    Contain: 'contain',
    ContainNotOnly: 'contain_not_only',
    Match: 'match',
    NotContain: 'not_contain',
    NotMatch: 'not_match'
} as const;
export type EnvelopeToSearchConditionV0TypeEnum = typeof EnvelopeToSearchConditionV0TypeEnum[keyof typeof EnvelopeToSearchConditionV0TypeEnum];


/**
 * Check if a given object implements the EnvelopeToSearchConditionV0 interface.
 */
export function instanceOfEnvelopeToSearchConditionV0(value: object): value is EnvelopeToSearchConditionV0 {
    if (!('field' in value) || value['field'] === undefined) return false;
    if (!('type' in value) || value['type'] === undefined) return false;
    if (!('values' in value) || value['values'] === undefined) return false;
    return true;
}

export function EnvelopeToSearchConditionV0FromJSON(json: any): EnvelopeToSearchConditionV0 {
    return EnvelopeToSearchConditionV0FromJSONTyped(json, false);
}

export function EnvelopeToSearchConditionV0FromJSONTyped(json: any, ignoreDiscriminator: boolean): EnvelopeToSearchConditionV0 {
    if (json == null) {
        return json;
    }
    return {
        
        'field': json['field'],
        'type': json['type'],
        'values': json['values'],
    };
}

export function EnvelopeToSearchConditionV0ToJSON(value?: EnvelopeToSearchConditionV0 | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'field': value['field'],
        'type': value['type'],
        'values': value['values'],
    };
}

