/* tslint:disable */
/* eslint-disable */
/**
 * HENNGE Email Archive API
 * HENNGE Email Archive administration and search API.
 *
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AccessPolicySearchPoliciesOptionV0
 */
export interface AccessPolicySearchPoliciesOptionV0 {
    /**
     * 
     * @type {string}
     * @memberof AccessPolicySearchPoliciesOptionV0
     */
    id: AccessPolicySearchPoliciesOptionV0IdEnum;
    /**
     * 
     * @type {string}
     * @memberof AccessPolicySearchPoliciesOptionV0
     */
    value: AccessPolicySearchPoliciesOptionV0ValueEnum;
}


/**
 * @export
 */
export const AccessPolicySearchPoliciesOptionV0IdEnum = {
    SearchPolicies: 'search_policies'
} as const;
export type AccessPolicySearchPoliciesOptionV0IdEnum = typeof AccessPolicySearchPoliciesOptionV0IdEnum[keyof typeof AccessPolicySearchPoliciesOptionV0IdEnum];

/**
 * @export
 */
export const AccessPolicySearchPoliciesOptionV0ValueEnum = {
    Read: 'read',
    Readwrite: 'read+write'
} as const;
export type AccessPolicySearchPoliciesOptionV0ValueEnum = typeof AccessPolicySearchPoliciesOptionV0ValueEnum[keyof typeof AccessPolicySearchPoliciesOptionV0ValueEnum];


/**
 * Check if a given object implements the AccessPolicySearchPoliciesOptionV0 interface.
 */
export function instanceOfAccessPolicySearchPoliciesOptionV0(value: object): value is AccessPolicySearchPoliciesOptionV0 {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('value' in value) || value['value'] === undefined) return false;
    return true;
}

export function AccessPolicySearchPoliciesOptionV0FromJSON(json: any): AccessPolicySearchPoliciesOptionV0 {
    return AccessPolicySearchPoliciesOptionV0FromJSONTyped(json, false);
}

export function AccessPolicySearchPoliciesOptionV0FromJSONTyped(json: any, ignoreDiscriminator: boolean): AccessPolicySearchPoliciesOptionV0 {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'value': json['value'],
    };
}

export function AccessPolicySearchPoliciesOptionV0ToJSON(value?: AccessPolicySearchPoliciesOptionV0 | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'value': value['value'],
    };
}

