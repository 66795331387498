/* tslint:disable */
/* eslint-disable */
/**
 * HENNGE Email Archive API
 * HENNGE Email Archive administration and search API.
 *
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { IdpV0 } from './IdpV0';
import {
    IdpV0FromJSON,
    IdpV0FromJSONTyped,
    IdpV0ToJSON,
} from './IdpV0';

/**
 * 
 * @export
 * @interface ProviderRequestV0
 */
export interface ProviderRequestV0 {
    /**
     * 
     * @type {string}
     * @memberof ProviderRequestV0
     */
    domainName: string;
    /**
     * 
     * @type {IdpV0}
     * @memberof ProviderRequestV0
     */
    idp?: IdpV0 | null;
    /**
     * 
     * @type {string}
     * @memberof ProviderRequestV0
     */
    loginHint?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProviderRequestV0
     */
    state?: string | null;
}



/**
 * Check if a given object implements the ProviderRequestV0 interface.
 */
export function instanceOfProviderRequestV0(value: object): value is ProviderRequestV0 {
    if (!('domainName' in value) || value['domainName'] === undefined) return false;
    return true;
}

export function ProviderRequestV0FromJSON(json: any): ProviderRequestV0 {
    return ProviderRequestV0FromJSONTyped(json, false);
}

export function ProviderRequestV0FromJSONTyped(json: any, ignoreDiscriminator: boolean): ProviderRequestV0 {
    if (json == null) {
        return json;
    }
    return {
        
        'domainName': json['domain_name'],
        'idp': json['idp'] == null ? undefined : IdpV0FromJSON(json['idp']),
        'loginHint': json['login_hint'] == null ? undefined : json['login_hint'],
        'state': json['state'] == null ? undefined : json['state'],
    };
}

export function ProviderRequestV0ToJSON(value?: ProviderRequestV0 | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'domain_name': value['domainName'],
        'idp': IdpV0ToJSON(value['idp']),
        'login_hint': value['loginHint'],
        'state': value['state'],
    };
}

