/* tslint:disable */
/* eslint-disable */
/**
 * HENNGE Email Archive API
 * HENNGE Email Archive administration and search API.
 *
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ArchiveHeadersV0,
  ArchiveSearchResultsV0,
  ArchiveSettingsV0,
  ArchiveStatusDetailV0,
  AttachmentPreviewResponseV0,
  DownloadFormatV0,
  ErrorResponsePayloadV0,
  ExtraMailDetailsV0,
  HeaderExportQueryV0,
  HeaderExportResponseV0,
  SearchHistoryV0,
  SearchQueryV0,
  URLResponseV0,
} from '../models/index';
import {
    ArchiveHeadersV0FromJSON,
    ArchiveHeadersV0ToJSON,
    ArchiveSearchResultsV0FromJSON,
    ArchiveSearchResultsV0ToJSON,
    ArchiveSettingsV0FromJSON,
    ArchiveSettingsV0ToJSON,
    ArchiveStatusDetailV0FromJSON,
    ArchiveStatusDetailV0ToJSON,
    AttachmentPreviewResponseV0FromJSON,
    AttachmentPreviewResponseV0ToJSON,
    DownloadFormatV0FromJSON,
    DownloadFormatV0ToJSON,
    ErrorResponsePayloadV0FromJSON,
    ErrorResponsePayloadV0ToJSON,
    ExtraMailDetailsV0FromJSON,
    ExtraMailDetailsV0ToJSON,
    HeaderExportQueryV0FromJSON,
    HeaderExportQueryV0ToJSON,
    HeaderExportResponseV0FromJSON,
    HeaderExportResponseV0ToJSON,
    SearchHistoryV0FromJSON,
    SearchHistoryV0ToJSON,
    SearchQueryV0FromJSON,
    SearchQueryV0ToJSON,
    URLResponseV0FromJSON,
    URLResponseV0ToJSON,
} from '../models/index';

export interface DefrostMailRequest {
    uidl: string;
}

export interface DownloadMailRequest {
    uidl: string;
    format: DownloadFormatV0;
}

export interface DownloadMailAttachmentRequest {
    uidl: string;
    attachmentId: string;
}

export interface ExportHeadersRequest {
    headerExportQueryV0: HeaderExportQueryV0;
}

export interface GetMailDetailsRequest {
    uidl: string;
    operationLogSearchCode?: string;
}

export interface GetMailHeadersRequest {
    uidl: string;
    operationLogSearchCode?: string;
}

export interface PreviewMailAttachmentRequest {
    uidl: string;
    attachmentId: string;
}

export interface SearchArchiveRequest {
    searchQueryV0: SearchQueryV0;
}

/**
 * 
 */
export class ArchiveApi extends runtime.BaseAPI {

    /**
     * This endpoint requests that the given mail be restored from tape. Once accepted, the defrosting will run in the background.  The user requires the permission `archive:mail:defrost` to be able to call this endpoint.  Error Codes:  - HTTP 403    - 1102 => the user does not have access to the given mail  - HTTP 422    - 2101 => the uidl specified can not be defrosted
     * Defrost mail.
     */
    async defrostMailRaw(requestParameters: DefrostMailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ArchiveStatusDetailV0>> {
        if (requestParameters['uidl'] == null) {
            throw new runtime.RequiredError(
                'uidl',
                'Required parameter "uidl" was null or undefined when calling defrostMail().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HTTPBearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v0/archive/mails/{uidl}/defrost`.replace(`{${"uidl"}}`, encodeURIComponent(String(requestParameters['uidl']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ArchiveStatusDetailV0FromJSON(jsonValue));
    }

    /**
     * This endpoint requests that the given mail be restored from tape. Once accepted, the defrosting will run in the background.  The user requires the permission `archive:mail:defrost` to be able to call this endpoint.  Error Codes:  - HTTP 403    - 1102 => the user does not have access to the given mail  - HTTP 422    - 2101 => the uidl specified can not be defrosted
     * Defrost mail.
     */
    async defrostMail(requestParameters: DefrostMailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ArchiveStatusDetailV0> {
        const response = await this.defrostMailRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * This endpoint will fetch the mail data for the given UIDL from the archive and send back a response with a location to download it in the requested format.  Formats:     - raw - the literal bytes of the mail stored in the archive     - readable - the bytes of the mail rendered as text in a readable format, with any parts with an 8-bit content transfer encoding transformed to 7-bit, producing an RFC 6531 like representation of the mail.   The user requires the following permissions to be able to call this endpoint:     - for format raw => `archive:mail:download-raw`     - for format readable => `archive:mail:download-readable`  Error Codes:  - HTTP 403    - 1102 => the user does not have access to the given mail
     * Download mail.
     */
    async downloadMailRaw(requestParameters: DownloadMailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<URLResponseV0>> {
        if (requestParameters['uidl'] == null) {
            throw new runtime.RequiredError(
                'uidl',
                'Required parameter "uidl" was null or undefined when calling downloadMail().'
            );
        }

        if (requestParameters['format'] == null) {
            throw new runtime.RequiredError(
                'format',
                'Required parameter "format" was null or undefined when calling downloadMail().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['format'] != null) {
            queryParameters['format'] = requestParameters['format'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HTTPBearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v0/archive/mails/{uidl}/download`.replace(`{${"uidl"}}`, encodeURIComponent(String(requestParameters['uidl']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => URLResponseV0FromJSON(jsonValue));
    }

    /**
     * This endpoint will fetch the mail data for the given UIDL from the archive and send back a response with a location to download it in the requested format.  Formats:     - raw - the literal bytes of the mail stored in the archive     - readable - the bytes of the mail rendered as text in a readable format, with any parts with an 8-bit content transfer encoding transformed to 7-bit, producing an RFC 6531 like representation of the mail.   The user requires the following permissions to be able to call this endpoint:     - for format raw => `archive:mail:download-raw`     - for format readable => `archive:mail:download-readable`  Error Codes:  - HTTP 403    - 1102 => the user does not have access to the given mail
     * Download mail.
     */
    async downloadMail(requestParameters: DownloadMailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<URLResponseV0> {
        const response = await this.downloadMailRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * This endpoint will fetch the mail data for the given UIDL, extract the matching attachment and send back a response with a location to download it.  The user requires the permission `archive:mail:attachment:download` to be able to call this endpoint.  Error Codes:  - HTTP 403    - 1102 => the user does not have access to the given mail
     * Download mail attachment.
     */
    async downloadMailAttachmentRaw(requestParameters: DownloadMailAttachmentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<URLResponseV0>> {
        if (requestParameters['uidl'] == null) {
            throw new runtime.RequiredError(
                'uidl',
                'Required parameter "uidl" was null or undefined when calling downloadMailAttachment().'
            );
        }

        if (requestParameters['attachmentId'] == null) {
            throw new runtime.RequiredError(
                'attachmentId',
                'Required parameter "attachmentId" was null or undefined when calling downloadMailAttachment().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HTTPBearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v0/archive/mails/{uidl}/attachments/{attachment_id}`.replace(`{${"uidl"}}`, encodeURIComponent(String(requestParameters['uidl']))).replace(`{${"attachment_id"}}`, encodeURIComponent(String(requestParameters['attachmentId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => URLResponseV0FromJSON(jsonValue));
    }

    /**
     * This endpoint will fetch the mail data for the given UIDL, extract the matching attachment and send back a response with a location to download it.  The user requires the permission `archive:mail:attachment:download` to be able to call this endpoint.  Error Codes:  - HTTP 403    - 1102 => the user does not have access to the given mail
     * Download mail attachment.
     */
    async downloadMailAttachment(requestParameters: DownloadMailAttachmentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<URLResponseV0> {
        const response = await this.downloadMailAttachmentRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * This endpoint will fetch all mail headers for a given time period and send back a response with a location to download it in CSV format and some metadata about that file.  The user requires the permission `archive:mail:export-headers` to be able to call this endpoint.  Error Codes:  - N/A
     * Export mail headers for a specific period of time in CSV format.
     */
    async exportHeadersRaw(requestParameters: ExportHeadersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<HeaderExportResponseV0>> {
        if (requestParameters['headerExportQueryV0'] == null) {
            throw new runtime.RequiredError(
                'headerExportQueryV0',
                'Required parameter "headerExportQueryV0" was null or undefined when calling exportHeaders().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HTTPBearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v0/archive/export_headers`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: HeaderExportQueryV0ToJSON(requestParameters['headerExportQueryV0']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => HeaderExportResponseV0FromJSON(jsonValue));
    }

    /**
     * This endpoint will fetch all mail headers for a given time period and send back a response with a location to download it in CSV format and some metadata about that file.  The user requires the permission `archive:mail:export-headers` to be able to call this endpoint.  Error Codes:  - N/A
     * Export mail headers for a specific period of time in CSV format.
     */
    async exportHeaders(requestParameters: ExportHeadersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<HeaderExportResponseV0> {
        const response = await this.exportHeadersRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * This endpoint returns the configured settings for email archiving.  The user requires the permission `archive:settings:get` to be able to call this endpoint.  Error Codes:  - HTTP 403    - 1102 => the user does not have access to view the archive settings.
     * Archive Settings
     */
    async getArchiveSettingsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ArchiveSettingsV0>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HTTPBearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v0/archive/settings`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ArchiveSettingsV0FromJSON(jsonValue));
    }

    /**
     * This endpoint returns the configured settings for email archiving.  The user requires the permission `archive:settings:get` to be able to call this endpoint.  Error Codes:  - HTTP 403    - 1102 => the user does not have access to view the archive settings.
     * Archive Settings
     */
    async getArchiveSettings(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ArchiveSettingsV0> {
        const response = await this.getArchiveSettingsRaw(initOverrides);
        return await response.value();
    }

    /**
     * This endpoint will return the details of the mail. The mail must be defrosted prior to calling this endpoint.  The user requires the permission `archive:mail:get-details` to be able to call this endpoint.  Error Codes:  - HTTP 403    - 1102 => the user does not have access to the given mail
     * Get extra details about the mail.
     */
    async getMailDetailsRaw(requestParameters: GetMailDetailsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ExtraMailDetailsV0>> {
        if (requestParameters['uidl'] == null) {
            throw new runtime.RequiredError(
                'uidl',
                'Required parameter "uidl" was null or undefined when calling getMailDetails().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['operationLogSearchCode'] != null) {
            queryParameters['operation_log_search_code'] = requestParameters['operationLogSearchCode'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HTTPBearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v0/archive/mails/{uidl}/extra_details`.replace(`{${"uidl"}}`, encodeURIComponent(String(requestParameters['uidl']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ExtraMailDetailsV0FromJSON(jsonValue));
    }

    /**
     * This endpoint will return the details of the mail. The mail must be defrosted prior to calling this endpoint.  The user requires the permission `archive:mail:get-details` to be able to call this endpoint.  Error Codes:  - HTTP 403    - 1102 => the user does not have access to the given mail
     * Get extra details about the mail.
     */
    async getMailDetails(requestParameters: GetMailDetailsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ExtraMailDetailsV0> {
        const response = await this.getMailDetailsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * This endpoint will return the headers of the mail.  The user requires the permission `archive:mail:get-headers` to be able to call this endpoint.  Error Codes:  - HTTP 403    - 1102 => the user does not have access to the given mail
     * Get mail headers.
     */
    async getMailHeadersRaw(requestParameters: GetMailHeadersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ArchiveHeadersV0>> {
        if (requestParameters['uidl'] == null) {
            throw new runtime.RequiredError(
                'uidl',
                'Required parameter "uidl" was null or undefined when calling getMailHeaders().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['operationLogSearchCode'] != null) {
            queryParameters['operation_log_search_code'] = requestParameters['operationLogSearchCode'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HTTPBearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v0/archive/mails/{uidl}/headers`.replace(`{${"uidl"}}`, encodeURIComponent(String(requestParameters['uidl']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ArchiveHeadersV0FromJSON(jsonValue));
    }

    /**
     * This endpoint will return the headers of the mail.  The user requires the permission `archive:mail:get-headers` to be able to call this endpoint.  Error Codes:  - HTTP 403    - 1102 => the user does not have access to the given mail
     * Get mail headers.
     */
    async getMailHeaders(requestParameters: GetMailHeadersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ArchiveHeadersV0> {
        const response = await this.getMailHeadersRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * This endpoint returns up to 100 recent searches for the current user.  The user requires the permission `archive:mail:search:history` to be able to call this endpoint.  Error Codes:  - N/A
     * Get recent search history for the user.
     */
    async getSearchHistoryRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SearchHistoryV0>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HTTPBearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v0/archive/search_history`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SearchHistoryV0FromJSON(jsonValue));
    }

    /**
     * This endpoint returns up to 100 recent searches for the current user.  The user requires the permission `archive:mail:search:history` to be able to call this endpoint.  Error Codes:  - N/A
     * Get recent search history for the user.
     */
    async getSearchHistory(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SearchHistoryV0> {
        const response = await this.getSearchHistoryRaw(initOverrides);
        return await response.value();
    }

    /**
     * This endpoint will fetch the mail data for the given UIDL, extract the matching attachment and send back a url for previewing the contents.  The user requires the permission `archive:mail:attachment:preview` to be able to call this endpoint.  Error Codes:  - HTTP 403    - 1102 => the user does not have access to the given mail  - HTTP 404    - 1001 => the mail does not have an attachment with this id    - 2301 => the given attachment in not supported for preview  - HTTP 500    - 2300 => an error occurred while previewing an attachment
     * Preview a mail attachment.
     */
    async previewMailAttachmentRaw(requestParameters: PreviewMailAttachmentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AttachmentPreviewResponseV0>> {
        if (requestParameters['uidl'] == null) {
            throw new runtime.RequiredError(
                'uidl',
                'Required parameter "uidl" was null or undefined when calling previewMailAttachment().'
            );
        }

        if (requestParameters['attachmentId'] == null) {
            throw new runtime.RequiredError(
                'attachmentId',
                'Required parameter "attachmentId" was null or undefined when calling previewMailAttachment().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HTTPBearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v0/archive/mails/{uidl}/attachments/{attachment_id}/preview`.replace(`{${"uidl"}}`, encodeURIComponent(String(requestParameters['uidl']))).replace(`{${"attachment_id"}}`, encodeURIComponent(String(requestParameters['attachmentId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AttachmentPreviewResponseV0FromJSON(jsonValue));
    }

    /**
     * This endpoint will fetch the mail data for the given UIDL, extract the matching attachment and send back a url for previewing the contents.  The user requires the permission `archive:mail:attachment:preview` to be able to call this endpoint.  Error Codes:  - HTTP 403    - 1102 => the user does not have access to the given mail  - HTTP 404    - 1001 => the mail does not have an attachment with this id    - 2301 => the given attachment in not supported for preview  - HTTP 500    - 2300 => an error occurred while previewing an attachment
     * Preview a mail attachment.
     */
    async previewMailAttachment(requestParameters: PreviewMailAttachmentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AttachmentPreviewResponseV0> {
        const response = await this.previewMailAttachmentRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * This endpoint searches for mails in the archive matching the given filters.  The user requires the permission `archive:mail:search` to be able to call this endpoint.  Error Codes:  - N/A
     * Search archive.
     */
    async searchArchiveRaw(requestParameters: SearchArchiveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ArchiveSearchResultsV0>> {
        if (requestParameters['searchQueryV0'] == null) {
            throw new runtime.RequiredError(
                'searchQueryV0',
                'Required parameter "searchQueryV0" was null or undefined when calling searchArchive().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HTTPBearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v0/archive/search`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SearchQueryV0ToJSON(requestParameters['searchQueryV0']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ArchiveSearchResultsV0FromJSON(jsonValue));
    }

    /**
     * This endpoint searches for mails in the archive matching the given filters.  The user requires the permission `archive:mail:search` to be able to call this endpoint.  Error Codes:  - N/A
     * Search archive.
     */
    async searchArchive(requestParameters: SearchArchiveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ArchiveSearchResultsV0> {
        const response = await this.searchArchiveRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
