/* tslint:disable */
/* eslint-disable */
/**
 * HENNGE Email Archive API
 * HENNGE Email Archive administration and search API.
 *
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ToCcBccSearchConditionV0
 */
export interface ToCcBccSearchConditionV0 {
    /**
     * 
     * @type {string}
     * @memberof ToCcBccSearchConditionV0
     */
    field: ToCcBccSearchConditionV0FieldEnum;
    /**
     * 
     * @type {string}
     * @memberof ToCcBccSearchConditionV0
     */
    type: ToCcBccSearchConditionV0TypeEnum;
    /**
     * 
     * @type {Array<string>}
     * @memberof ToCcBccSearchConditionV0
     */
    values: Array<string>;
}


/**
 * @export
 */
export const ToCcBccSearchConditionV0FieldEnum = {
    ToCcBcc: 'to_cc_bcc'
} as const;
export type ToCcBccSearchConditionV0FieldEnum = typeof ToCcBccSearchConditionV0FieldEnum[keyof typeof ToCcBccSearchConditionV0FieldEnum];

/**
 * @export
 */
export const ToCcBccSearchConditionV0TypeEnum = {
    Contain: 'contain',
    ContainNotOnly: 'contain_not_only',
    Match: 'match',
    NotContain: 'not_contain',
    NotMatch: 'not_match'
} as const;
export type ToCcBccSearchConditionV0TypeEnum = typeof ToCcBccSearchConditionV0TypeEnum[keyof typeof ToCcBccSearchConditionV0TypeEnum];


/**
 * Check if a given object implements the ToCcBccSearchConditionV0 interface.
 */
export function instanceOfToCcBccSearchConditionV0(value: object): value is ToCcBccSearchConditionV0 {
    if (!('field' in value) || value['field'] === undefined) return false;
    if (!('type' in value) || value['type'] === undefined) return false;
    if (!('values' in value) || value['values'] === undefined) return false;
    return true;
}

export function ToCcBccSearchConditionV0FromJSON(json: any): ToCcBccSearchConditionV0 {
    return ToCcBccSearchConditionV0FromJSONTyped(json, false);
}

export function ToCcBccSearchConditionV0FromJSONTyped(json: any, ignoreDiscriminator: boolean): ToCcBccSearchConditionV0 {
    if (json == null) {
        return json;
    }
    return {
        
        'field': json['field'],
        'type': json['type'],
        'values': json['values'],
    };
}

export function ToCcBccSearchConditionV0ToJSON(value?: ToCcBccSearchConditionV0 | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'field': value['field'],
        'type': value['type'],
        'values': value['values'],
    };
}

